@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
*,
::after,
::before {
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif !important;
  background-color: #fff !important;
}
html,
body {
  height: 100%;
}
#root {
  height: 100%;
}
:root {
  --satinDeepblackColor: #1c1d1f;
  --sharkFinColor: #959595;
  --SnowflakeColor: #f0f0f0;
  --bloodDonorColor: #ec171c;
}
.displayInlineFlex {
  display: inline-flex;
}
.displayFlex {
  display: flex;
}
.flexDirectionColumn{
  flex-direction: column;
}
.displayNone {
  display: none;
}
.alignItemCenter {
  align-items: center;
}
.mb_15{
  margin-bottom: 15px;
}
.mb_5{
  margin-bottom: 5px;
}
.justifyContent_end {
  justify-content: end;
}
.leaflet-container {
  height: 130vh;
  width: 90vh;
}
.justifyContent_start {
  justify-content: start;
}
.justifyContent_center {
  justify-content: center;
}
.justifyContent_spacebetween {
  justify-content: space-between;
}
.positionRelative {
  position: relative !important;
}
.customAccordionItem {
  border-radius: 13px;
  background-color: var(--SnowflakeColor);
  padding: 12px 0px;
}
.deliveryTitle {
  color: #347928;
}
.pickupTitle {
  color: #006BFF;
}
.p12{
 padding: 12px;
}
.rs-picker-popup {
  z-index: 1051 !important;
}
.p18{
  padding: 18px;
 }
 .fw600{
  font-weight: 600 !important;
 }
.greycol{
  color: grey;
  font-weight: 800;
  margin-top: 10px !important;
}
.mt_20 {
  margin-top: 20px !important;
}
.buttonIcon {
  color: #EC171C;
}
.mtDate{
  margin-top: 0px;
}
.remove-icon {
  cursor: pointer;
  margin-left: 10px;
  color: #EC171C;
}
.mt_-15 {
  margin-top: -15px !important;
}
.mt_-10 {
  margin-top: -9px !important;
}
.mt_-100 {
  margin-top: -100px !important;
}
.mt_4{
  margin-top: 4px !important;
}
.mt_30 {
  margin-top: 30px !important;
}
.mt_2{
  margin-top: 2px !important;
}
.dateTop{
  margin-top: 48px !important;
}
.mt_-2{
  margin-top: -2px;
}
.mt_-24 {
  margin-top: -24px !important;
}
.mt_-28 {
  margin-top: -27px !important;
}
.mr_15{
  margin-right: 15px;
}
.mr_-30{
  margin-right: -30px !important;
}
.mr_150{
  margin-right: 150px;
}
.mt_-40 {
  margin-top: -40px !important;
}
.mr_5{
  margin-right: 5px !important;
}
.w48{
  width: 48.5% !important;
}
.mr_45{
  margin-right: 45px;
}
.mright_15{
  margin-right: 15px;
}
.mr_auto{
  margin-right: auto;
}
.mright_40{
  margin-right: 40px;
}
.mright_20{
  margin-right: 20px;
}
.mt_Rates{
  margin-top: 0px !important;
}
.mt_45{
  margin-top: 45px !important;
}
.mt_50{
  margin-top: 50px !important;
}
.persistent-red-border .select__control {
  border-color: #ec171c !important;
  box-shadow: 0 0 0 1px #ec171c !important;
}
.mt_55{
  margin-top: 54px !important;
}
.mt_25{
  margin-top: 25px !important;
}
.mt_5{
  margin-top: 5px !important; 
}
.mt_6{
  margin-top: 6px !important; 
}
.mt_15{
  margin-top: 15px !important;
}
.mt_70{
  margin-top: 70px !important;
}
.mr_10p{
  margin-right: 10px !important;
}
.mt_60 {
  margin-top: 60px;
}
.mt_60ops{
  margin-top: 75px;
}
.mt_24 {
  margin-top: 24px;
}
.mb_0{
  margin-bottom: 0px;
}
.mb_-5{
  margin-bottom: -5px !important;
}
.mb_-14{
  margin-bottom: -14px !important;
}
.mb_24 {
  margin-bottom: 24px !important;
}
.mt_12 {
  margin-top: 12px !important;
}
.mb_22 {
  margin-bottom: 14px !important;
}
.mb_12 {
  margin-bottom: 12px !important;
}
.mb_18 {
  margin-bottom: 18px !important;
}
.mt_40 {
  margin-top: 40px !important;
}
.ml_auto{
  margin-left: auto;
}
.ml_5 {
  margin-left: 5px !important;
}
.ml_50 {
  margin-left: 50px !important;
}
.ml_-50 {
  margin-left: -50px !important;
}
.ml_-24{
  margin-left: -24px !important;
}
.w50{
  width: 50px !important;
}
.mb_16 {
  margin-bottom: 16px !important;
}
.mb_60 {
  margin-bottom: 60px !important;
}
.mr_10px {
  margin-right: 10px !important;
}
.mr_10 {
  margin-right: -10px;
}
.mt_-25{
  margin-top: -25px !important;
}
.mt_-42{
  margin-top: -42px !important;
}
.mt_-33{
  margin-top: -33px !important;
}
.mb_8 {
  margin-bottom: 8px !important;
}
.mb_80 {
  margin-bottom: 80px !important;
}
.mt_8 {
  margin-top: 8px !important;
}
.mbscan {
  margin-bottom: 4px !important;
  margin-top: 8px;
}
.mr_16 {
  margin-right: 16px !important;
}
.mr_66 {
  margin-right: 66px !important;
}
.mt_14 {
  margin-top: 14px !important;
}
.ml_auto {
  margin-left: auto;
}
.ml_inherit {
  margin-left: inherit !important;
}
.cursorPointer {
  cursor: pointer;
}
.customAccordionSummary {
  background-color: #fafbfc !important;
  color: #333 !important;
}
.mr_25 {
  margin-right: 25px;
}
.mt_reset{
  margin-top: -60px !important;
  margin-left: 120px !important;
  margin-bottom: -20px;
}
.mb_20{
  margin-bottom: 20px;
}
.gap_16 {
  gap: 16px;
}
.gap_12 {
  gap: 12px;
}
.directory {
  margin-bottom: 30px; /* Space between each directory */
}

.directory-name {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

.file-list {
  padding-left: 20px;
}

.file-item {
  margin-bottom: 10px; /* Space between file names */
}

.file-nme {
  font-size: 16px;
  color: #007bff; 
  text-decoration: underline; 
  text-decoration-thickness: 1px; 
  text-underline-offset: 3px; 
}
.file-nme:hover {
  color: #0056b3; 
  text-decoration-thickness: 2px;
}
.no-files {
  font-style: italic;
  color: #999;
}
.mapContainer {
  width: 735px;
  height: 600px; /* Adjust the height to take up a portion of the viewport */
  border: 2px solid #000;
  border-radius: 5px;
}
.margin-top-5{
  margin-top: -15px !important;
}
.margin-top-10{
  margin-top: -10px !important;
}
.margin-top-15{
  margin-top: -15px !important;
}
.width_280 {
  width: 280px;
}
.width_100per {
  width: 100%;
}
/* Ensuring the parent row centers its content */
.row.justify-content-center {
  display: flex;
  justify-content: center;
}
.debreifAlign{
  margin-left: 400px !important;
}
.debriefSmallTables{
  margin-left: 40px !important;
}
/* Optional: Text center on small screens */
.text-center {
  text-align: center;
}

/* Optionally, adjust spacing between elements */
.mtop_10 {
  margin-top: 10px;
}

.mleft_10 {
  margin-left: 10px;
}

.mt_30px {
  margin-top: 30px !important;
}
.mt_-30px {
  margin-top: -30px !important;
}
.zIndex999 {
  z-index: 9999;
}
.no-hover th {
  cursor: default !important;
}
.tableClass thead:hover {
  background-color: inherit !important; 
  color: inherit !important;   
}

.tableClass th {
  cursor: pointer !important; /* Keep the pointer cursor on header cells */
}

.tableClass th:hover {
  background-color: transparent !important; /* No background change on hover */
}
.carousel-container {
  // height: 300px; /* Adjust to your desired height */
  overflow: hidden; /* Ensures that content outside this height doesn't show */
}
.carousel-image {
  width:45%;
  height: 120%;
  object-fit: cover; /* This ensures the image covers the container while maintaining its aspect ratio */
}
.emptyUnorderList {
  padding-left: 0px;
  margin: 0px;
  list-style: none;
}
.zIndex1000 {
  z-index: 1000 !important;
}
.customSearchInput {
  width: 300px;
}
.greycolnew {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal; /* Allows text to wrap */
}

.empty_btn {
  background: transparent;
  outline: unset;
  border: unset;
  padding: 0px;
}
.textAlignCenter {
  text-align: center;
}

.blackText {
  color: #000;
  line-height: 1.5;
  margin: 0px;
}
.marginL8{
  margin-left: 8px !important;
}
.marginL5{
  margin-left: 6px !important;
}
.w115{
  width: 115px;
}
.w165{
  width: 165px;
}
.w197{
  width: 197px !important;
}
.ml_15{
  margin-left: 15px;
}
.mt_-5{
  margin-top: -5px !important;
}
.marginL12{
  margin-left: 12px !important;
}
.marginL-12{
  margin-left: -12px !important;
}
.menacingCloudsText {
  color: #000;
  line-height: 1.5;
  margin: 0px;
}
.textDecoration_underline {
  text-decoration: underline;
}
.fontSize12 {
  font-size: 12px;
}
.fontSize14 {
  font-size: 13px;
}
.fontSize15 {
  font-size: 14px;
}
.fontSize16 {
  font-size: 16px;
}
.fontSize18 {
  font-size: 18px;
}
.fontSize20 {
  font-size: 20px;
}
.fontSize24 {
  font-size: 24px;
}
.margintop7{
  margin-top: 7px !important;
}
.fontWeight400 {
  font-weight: 400;
}
.fontWeight500 {
  font-weight: 500;
}
.fontWeight600 {
  font-weight: 600;
}
.satinDeepblackText {
  line-height: 1.5;
  margin: 0px;
  color: var(--satinDeepblackColor);
}
.sharkFinText {
  line-height: 1.5;
  margin: 0px;
  color: var(--sharkFinColor);
}
.selectpackageItem{
  border-bottom: 1px solid #EFEFEF;
  padding: 16px 34px;
  }
  .selectpackageItem:last-child{
    border-bottom: unset !important;
  }
  .selectpackageItem:hover{
    // background: #f8f8f8;
    background: #e0e0e0;
  }
  
/* login page  */
img.login_logo {
  width: 80px;
  margin: 0px auto 16px;
}
.fullBg {
  background-color: #f6f6f6 !important;
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rowHover:hover {
  background-color: blue;
}
.login_sec {
  display: flex;
  width: 380px;
  margin: auto;
  flex-direction: column;
  padding: 30px 30px 16px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(26, 32, 36, 0.32);
  border-radius: 10px;
}
.login_sec h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  margin: 0px;
  text-align: center;
}
.login_sec p {
  text-align: center;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  margin: 0px;
  color: #878a99;
}
.ws_nowrap {
  white-space: nowrap !important;
}
.mainInput .rs-input-placeholder {
  white-space: nowrap;
}
.customSearchInput .rs-input-placeholder {
  white-space: nowrap;
}
.title_border {
  border-bottom: 3px solid #d30b24;
  border-bottom: 3px solid #d30b24;
  border-radius: 31px;
  display: block;
  margin: 12px auto 24px;
  width: 50px;
}
.inputTitle {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
  line-height: 1.5;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.login_sec h2 span {
  color: #d30b24;
}

.login_btn {
  height: 36px;
  border-radius: 10px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  color: #ffffff;
  border: 1px solid #1c1d1f;
  outline: unset;
  width: 100%;
  background-color: #1c1d1f;
}

.login_btn:hover {
  border: 1px solid #1c1d1f;
  color: #1c1d1f;
  background-color: transparent;
}

.Maininput {
  border: 1px solid #bcc3c7;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  background-color: transparent;
  outline: unset;
  color: #7a869a;
  border-radius: 10px;
  height: 40px;
  width: 100%;
  padding: 0px 12px;
}
// .mainInput:focus {
//   outline: 1px solid; /* Change the color and size of the focus outline as needed */
// }

.smallMaininput {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  background-color: transparent;
  border: 2px solid #dfe1e6;
  outline: unset;
  color: #7a869a;
  border-radius: 3px;
  height: 36px;
  width: 100%;
  padding: 0px 12px;
}
.smallMaininput::placeholder {
  color: #6d6f82;
}

.InputGroup {
  display: flex;
}

.InputGroupFilter {
  display: flex;
}

.InputGroupText {
  display: flex;
  align-items: center;
  padding: 0px 12px;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 1px solid #bcc3c7;
  border-radius: 10px;
  height: 40px;
}

.InputGroup input {
  background-color: transparent;
  border: 1px solid #bcc3c7;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  // width: 100%;
  width: 150px;
  height: 36px;
  // border-radius: 50px;
  padding: 0px 12px 0px 12px;
  outline: unset;
  color: #1c1d1f;
}

.InputGroup > input:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: unset;
}

.InputGroup-prepend .InputGroupText {
  margin-right: -1px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.InputGroup-append .InputGroupText {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -5px;
  border-left: unset;
}

.InputGroup:not(.has-validation) > input:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: unset;
}

.InputGroupFilter input {
  background-color: transparent;
  border: 1px solid #bcc3c7;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  // width: 100%;
  width: 150px;
  height: 36px;
  // border-radius: 50px;
  padding: 0px 12px 0px 12px;
  outline: unset;
  color: #1c1d1f;
}

.InputGroupFilter > input:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: unset;
}

.InputGroupFilter-prepend .InputGroupText {
  margin-right: -1px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.InputGroupFilter-append .InputGroupText {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -5px;
  border-left: unset;
}

.InputGroupFilter:not(.has-validation) > input:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: unset;
}
.success-tick {
  background-color: green;
  color: white;
  padding: 5px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}

.tabletab_sectionnew {
  display: flex;
  border-bottom: 2px solid #ddd;
}

.tabletab_sectionnew {
  display: flex;
}
/* Grid Layout */
.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem; 
  margin: 20px 0;
  max-width: 100%; 
  box-sizing: border-box; 
}
/* Box styles */
.info-box {
  border: 2px solid #4CAF50; 
  background-color: #f9f9f9;
  padding: 10px;           
  text-align: left;          
  min-width: 360px;         
  width: 100%;             
  box-sizing: border-box;  
  margin-right: 15px;      
  border-radius: 8px;       
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}
/* Row styles */
.info-row {
  display: flex;           
  justify-content: space-between;
  align-items: center;    
  margin-bottom: 8px;      
  border-bottom: 1px dashed #ddd;
  padding: 5px 0;          
}
/* Styling for labels */
.info-label {
  font-weight: bold;       
  margin-right: 5px;      
  flex:0.5 1;                 
  text-align: left;         
  border-right: 1px solid #4CAF50; 
  padding-right: 10px;     
  color: #333;           
}
/* Styling for values */
.info-value {
  flex: 1;                 
  text-align: left;        
  padding-left: 10px;     
  color: #000;            
}
/* Add hover effect for the entire box */
.info-box:hover {
  background-color: #e8f5e9; 
  border-color: #2e7d32;  
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); 
}

.info-label:nth-child(1) {
  color: #673ab7;          
}
/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .info-grid {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Adjust grid layout for smaller screens */
  }

  .info-box {
    margin-right: 10px; /* Reduce margin for smaller screens */
  }
}
/* Media query for very small screens */
@media screen and (max-width: 480px) {
  .info-box {
    width: 100%; /* Ensure full width on very small screens */
    margin-right: 0; /* Remove margin on small screens */
  }

  .info-grid {
    grid-template-columns: 1fr; /* Make it a single column grid for very small screens */
  }
}
.tabBtnnew {
  background: none;
  border: none;
  padding: 10px 20px;
  margin: 0;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s, color 0.3s;
}

.tabBtnnew:hover,
.tabBtnnew:focus {
  color: #555;
}

.activeTabnew {
  border-bottom: 2px solid red;
  color: red;
}

.tabBtnnew[aria-selected="true"] {
  outline: none; /* Optional: remove outline for selected tab */
}


.marginR10 {
  margin-right: 10px;
}
.marginBotton_20{
  margin-bottom: 20px;
}
.col-13-percent {
  width: 8.1% !important;
}
.col-8-percent {
  width: 8.1% !important;
}
.col-25-percent {
  width: 24% !important;
}
.col-18-percent {
  width: 11.7% !important;
}
.col-17-percent {
  width: 11.7% !important;
}
.col-20-percent {
  width: 13.5% !important;
}
.col-13-5-percent {
  width: 13.5% !important;
}
.col-14-percent {
  width: 17% !important;
}
.col-28-percent {
  width: 28% !important;
  margin-top: 10px !important;
}
.col-40-percent {
  width: 41% !important;
  margin-top: 10px !important;
}
.col-50-percent {
  width: 50% !important;
  margin-top: 10px !important;
}
.col-100-percent {
  width: 100% !important;
  margin-top: 10px !important;
}
.col-28-percent-ms {
  width: 28% !important;
}
.col-40-percent-ms {
  width: 41% !important;
}
.col-50-percent-ms {
  width: 50% !important;
}
.col-100-percent-ms {
  width: 100% !important;
}
.col-35-percent {
  width: 35% !important;
}
.mobileAdminlogo {
  display: none;
}
.adminTogglemenu {
  display: none;
}
.layoutWrapper {
  display: flex;
  min-height: calc(100% - 56px);
}
.w_110{
  width: 115px !important;
}
.admin_sidebar {
  position: relative;
  width: 250px;
  background-color: #fafbfc;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 0px 40px;
  padding: 14px 24px;
  // position: fixed;
  // height: 100%;
  // z-index: 999;\
  // top:70px
  border-right: 1px solid #d4d1d1;
}
//notification button
.button-groupsms {
  display: flex;
}
.no-report-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  color: #555;
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}
.no-report-text {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}
.no-report-subtext {
  font-size: 16px;
  color: #777;
}

.sidebar-container {
  display: flex;
  height: 100vh;
  background-color: #f8f9fa;
}

/* Sidebar Section */
.sidebar {
  width: 175px;
  background-color: #ffffff;
  border-right: 1px solid #ddd;
  padding: 20px;
  padding-right: 0; 
  padding-left: 6px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  margin-left: -30px;
}

.sidebar-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
}
.list-group-item {
  border: none !important;            
  border-radius: 0 !important;         
  padding: 4px 10px !important;      
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  background-color: #fff;   
  color: #333;
  outline: none;           
}

.list-group-item:hover {
  background-color: #fff2f2; 
}

.list-group-item:focus,
.list-group-item.active {
  background-color: white !important; /* White background */
  color: #EC171C !important; /* Red text */
  font-weight: normal !important; /* Ensure text is not bold */
}

.list-group-item:focus-visible {
  outline: none !important; /* Remove outline on focus */
}

.active-report {
  background-color: #EC171C !important;
  color: white !important;
  font-weight: bold;
}
/* Report Iframe Section */
.report-view {
  flex-grow: 1;
  background-color: #ffffff;
}

.iframe-container {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Iframe Link Icon */
.iframe-header {
  position: absolute;
  top: 30px;
  right: 15px;
  z-index: 10;
}

.iframe-url-link img {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.iframe-url-link img:hover {
  transform: scale(1.1);
}
/* Main Category List */
.category-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Category Header (Finance, Operations, etc.) */
.category-item {
  background: #f8f9fa; /* Light gray background */
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

/* Arrow for Expand/Collapse */
.arrow {
  font-size: 14px;
  color: #666;
}

/* Sub-list (Reports) */
.sub-list {
  list-style-type: none;
  margin-top: 5px;
  padding-left: 0px;
}
/* Each Report Item */
.sub-item {
  margin: 5px 0;
}

/* Report Links */
.sub-link {
  text-decoration: none;
  color: #007bff;
  display: block;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background 0.2s;
}

.sub-link:hover {
  background: #e9ecef; /* Light hover effect */
}

/* Active Link */
.sub-link.active {
  background: #007bff;
  color: white;
  font-weight: bold;
}

/* Loading & Error Styles */
.loading-text,
.error-text {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.error-text {
  color: red;
}

/* Iframe Styling */
.custom-iframe {
  border: none;
  border-radius: 8px;
}

/* Header Button */
.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 12px 15px;
  font-weight: bold;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-size: 16px;
}

/* Report List */
.report-list {
  padding: 0;
  margin: 0;
}

/* Report Items */
.custom-list-item {
  padding: 12px;
  font-size: 14px;
  border: none;
  text-align: left;
  background-color: white;
  transition: 0.3s;
}

/* Selected Report */
.selected-report {
  background-color: #EC171C !important;
  color: white !important;
}

/* Hover Effect */
.custom-list-item:hover {
  background-color: #ffebeb !important;
}
.discrepancy-wrapper {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust spacing between dropdown and button */
}
.buttonSms {
  padding: 5x 10px;
  margin-right: 10px; /* Add space between buttons */
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.buttonSms:last-child {
  margin-right: 0; /* Remove margin from the last button */
}
.content-wrapper {
  max-width: 600px; /* Optional: restrict content width */
  padding: 20px; /* Optional: add padding */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow */
  background: #fff; /* Optional: white background */
  border-radius: 8px; /* Optional: rounded corners */
}
.buttonSms.selected {
  background-color: #EC171C;
  color: white;
  border-color: #EC171C;
}

.buttonSms:hover {
  background-color: #EC171C;
  color: white;
}
.Main_wrapper {
  //   margin-left: 250px;
  width: calc(100% - 250px);
  display: inline-block;
  padding: 24px;
}
/* Style for the list container */
.discrepancyList {
  max-height: 300px; /* Add a scrollable height if there are many items */
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}
.revenueList {
  max-height: 1300px; /* Add a scrollable height if there are many items */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}
/* Style for each item */
.discrepancyItem {
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style for labels (e.g., Tracking ID, Package Tracking ID) */
.discrepancyItem p {
  margin: 5px 0;
  font-size: 14px;
  color: #333;
}

.discrepancyItem strong {
  color: #000;
  font-weight: 500;
}
.discrepancyItem:hover {
  background-color: #fff2f2 ; 
  border-color: #ec171c;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}
/* Style for each item */
.revenueItem {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Style for labels (e.g., Tracking ID, Package Tracking ID) */
.revenueItem p {
  font-size: 14px;
  margin-bottom: 8px;
  color: #333; /* Dark grey for text */
}

.revenueItem strong {
  font-weight: 600;
  color: #000; /* Black for values */
}
.revenueItem span {
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  color: #666; /* Slightly lighter grey for labels */
}
/* Add hover effect for each item (optional) */
.revenueItem:hover {
  background-color: #fff2f2 ; 
  border-color: #ec171c;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.topHeader {
  background-color: #fff;
  // box-shadow: rgba(149, 157, 165, 0.2) 0px 0px 40px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px 10px 24px;
  z-index: 1039;
  position: relative;
  height: 56px;
  //     position: fixed;
  // top: 0px;
  // width: 100%;
  // z-index: 9999;
}
.layoutfooter {
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 0px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px 16px 12px;
}
.admin_sidebar ul {
  list-style: none;
  //   padding: 16px 0px;
  padding: 0px;
  margin: 0px;
}

.admin_sidebar ul li {
  margin-bottom: 12px;
}

.admin_sidebar ul li.menuTitle {
  background-color: rgba(0, 0, 0, 0.3);
  padding: 10px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: 0.8px;
}

.admin_sidebar ul li:last-child {
  margin-bottom: 0px;
}

.admin_sidebar ul li button {
  background-color: transparent;
  border: 1px solid transparent;
  text-decoration: unset;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.006em;
  padding: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  color: #1c1d1f;
  transition: 0.3s;
  text-align: left;
}
.admin_sidebar ul li a {
  background-color: transparent;
  border: 1px solid transparent;
  text-decoration: unset;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.006em;
  padding: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  color: #1c1d1f;
  transition: 0.3s;
  text-align: left;
}
.admin_sidebar ul li button.active {
  color: #ec171c;
}
.admin_sidebar ul li a.active {
  color: #ec171c;
}
.admin_sidebar ul li button.active img {
  filter: brightness(0) saturate(100%) invert(10%) sepia(95%) saturate(5737%)
    hue-rotate(352deg) brightness(109%) contrast(90%);
}

ul.adminsubmenu {
  padding-left: 16px !important;
}
.pl16 {
  padding-left: 16px !important;
}
ul.adminsubmenu li {
  margin-bottom: 12px;
}

ul.adminsubmenu li button.active {
  background-color: transparent;
  color: #03a9f4;
}
// ul.adminsubmenu li button img{
//   width: 18px;
//   height: 18px;
// }
.layoutWrapper.show ul.adminsubmenu {
  padding-left: 0px !important;
}
.admin_logo img {
  width: 65px;
}
.layoutWrapper.show .admin_logo {
  text-align: center;
  padding: 24px 0px 34px;
  margin: 0px;
}
.layoutWrapper.show .admin_sidebar ul li button {
  justify-content: center;
}
.layoutWrapper.show .admin_sidebar ul li button img {
  margin: 0px;
}

.layoutWrapper.show .admin_logo img {
  width: 70px;
}
.layoutWrapper.show img.dropdownIcon {
  display: none;
}

// sidebar expand style

.layoutWrapper.show .admin_sidebar {
  width: 80px;
}
.br5{
  border-radius: 5px;
}
.mr190{
  margin-right: 190px !important;
}
.layoutWrapper.show .admin_sidebar ul li {
  width: 35px;
  margin: 0px auto 12px;
  justify-content: center;
  border-radius: 5px;
  padding: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.layoutWrapper.show .admin_sidebar ul li span {
  display: none;
}

.layoutWrapper.show .Main_wrapper {
  //   margin-left: 80px;
  width: calc(100% - 80px);
}

.userProfile .btn-primary {
  background-color: unset;
  border: 1px solid #e1e6e7;
  border-radius: 8px;
  padding: 2px 10px;
  color: #707070;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
}
.userProfile .btn:first-child:active {
  background-color: unset;
  color: #707070;
  border: 1px solid #e1e6e7;
}
.userProfile .btn-primary:hover {
  background-color: unset;
  color: #707070;
  border: 1px solid #e1e6e7;
}
.userProfile .btn-primary:active {
  color: #707070;
}
.userProfile .btn-primary:focus {
  background-color: #e1e6e7;
  border: 1px solid #e1e6e7;
  color: #707070;
}

.userProfile img {
  width: 30px;
  height: 30px;
}
.width243{
  width: 243px !important;
}
.width208{
  width: 208px !important;
}
.dropdown-toggle::after {
  vertical-align: 0.155em !important;
}

.userProfile .dropdown-menu.show {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: unset;
  border-radius: 5px !important;
  top: 0px !important;
}

.userProfile .dropdown-menu.show a {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
}

.userProfile .dropdown-menu.show a:hover {
  text-decoration: none !important;
}

.userProfile .dropdown-toggle::after {
  margin-left: 10px;
}

.loaderBlock {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999999;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-svg {
  width: 50px;
  height: 50px;
  animation: rotate 2s linear infinite;
  stroke: #ec171c;
  fill: none;
  transform-origin: center;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
.printOrder {
  position: absolute;
  top: 45px;
  right: 0px;
  padding: 8px 16px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
}
.desktoptogglemenu {
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px,
    rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  position: absolute;
  top: 30px;
  z-index: 999;
  background: #ffff;
  right: -13.5px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-top: -16px;
}
.desktoptogglemenu:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(20deg) brightness(103%) contrast(103%);
}
.desktoptogglemenu:hover {
  background-color: #1c1d1f;
}
.w280{
  width: 280px !important;
}
// .desktoptogglemenumin{
//   position: relative;
//   height: 26px;
//   top: -38px;
//   left: 48px;
// }

// .whiteFrame{
//     background-color: #fff;
//     border-radius: 10px;
//     padding: 24px;
// }
.dashBack {
  font-size: 18px;
}
.customDateRange {
  position: relative;
  border-radius: 5px;
}
.customDateRange .input {
  border: 1px solid #909497; /* Change default border color */
  box-shadow: none; /* Remove any box-shadow */
  height: 44px !important;
  padding: 0px 16px !important;
}
.react-datepicker-wrapper {
  position: absolute;
}
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: unset !important;
}
.MainInputDate {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
  height: 44px;
  font-family: var(--fontFamily);
  border: 1px solid var(--cerebralGrey_Color);
  outline: unset;
  border-radius: 5px;
  background-color: transparent;
  padding: 0px 16px;
}

.MainInputDate::placeholder {
  color: var(--scatteredShowers_Color);
}
.generatePassword{
  margin-left: -150px !important;
  width: 150%;
  margin-top: 45px !important;
}
.wrapper-list {
  display: inline-flex;
  flex-direction: column;
  position: fixed;
  top: 122px;
  left: 124px;
}
ul.listColumn {
  padding: 0px;
  list-style: none;
  margin: 0px;
}
ul.listColumn li {
  font-size: 12px;
  // margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  list-style: none;
  padding: 6px 10px;
  border-left: 3px solid transparent;
}
ul.listColumn li:last-child {
  margin-bottom: 0px;
}
ul.listColumn li:hover {
  background-color: #f4f5f7;
  color: #091e42;
  border-left: 3px solid #0052cc;
}

.customFilter a.dropdown-item {
  text-decoration: none !important;
  padding: 0px;
}
.customFilter .dropdown-menu.show {
  // height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border: unset;
  top: 6px !important;
}
.customFilter .dropdown-item:focus,
.customFilter .dropdown-item:hover {
  background-color: unset !important;
}

.customFilter .dropdown-menu.show::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.customFilter .btn.show {
  background-color: rgba(9, 30, 66, 0.04);
  border: 1px solid transparent;
}
.customFilter .btn:first-child:active {
  background-color: rgba(9, 30, 66, 0.04);
  border: 1px solid transparent;
}
.customFilter .btn-primary {
  background-color: rgba(9, 30, 66, 0.04);
  border: 1px solid transparent;
  font-size: 12px;
  padding: 4px 10px;
  border-radius: 3.01px;
  height: 30px;
}
.customFilter .btn-primary:hover {
  background-color: #ec171c;
  color: #fff;
  border: 1px solid transparent;
}
.customFilter .btn-primary:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%)
    hue-rotate(190deg) brightness(103%) contrast(102%);
}

.customFilter .dropdown-toggle::after {
  display: none;
}

//required designs
.requiredStar{
  position: absolute;
  right: -14px;
  top: 0;
  }
  .requiredStarTwo{
    position: absolute;
    right: 25px;
    top: 0;
    }
  .bloodDonortext {
    line-height: 1.5;
    margin: 0px;
    color: var(--bloodDonorColor);
  }

/* Track */
.customFilter .dropdown-menu.show::-webkit-scrollbar-track {
  background: #dfdfdf;
  border-radius: 5px;
}

/* Handle */
.customFilter .dropdown-menu.show::-webkit-scrollbar-thumb {
  background: #818181;
  border-radius: 5px;
}

.customDateRange .rs-picker-toggle-wrapper {
  width: 100%;
}
.customDateRange .rs-picker-default .rs-picker-toggle {
  height: 36px;
  border-radius: 3px;
}
.customDateRange .rs-picker-toggle {
  border: 2px solid #dfe1e6 !important;
}
.customDateRange .rs-picker:not(.rs-picker-disabled):hover,
.customDateRange .rs-picker:not(.rs-picker-disabled):focus {
  // box-shadow: unset;
  border-color: unset;
}
.customDateCusRange .rs-picker-toggle-wrapper {
  width: 50%;
}
.customDateCusRange .rs-picker-default .rs-picker-toggle {
  height: 36px;
  border-radius: 3px;
}
.custom-accordion-header {
  font-size: 14px;          
  padding: 0px 0px;        
  line-height: 0.2;    
  background-color: white !important;
  color: black; 
  border: none;     
}

.customDateCusRange .rs-picker-toggle {
  border: 2px solid #dfe1e6 !important;
}
.customDateCusRange .rs-picker:not(.rs-picker-disabled):hover,
.customDateRange .rs-picker:not(.rs-picker-disabled):focus {
  // box-shadow: unset;
  border-color: unset;
}
.table_wrapper {
  padding-bottom: 24px;
}
.showFiveRows {
  max-height: 270px;
  overflow-y: auto;
  padding-bottom: 0 !important;
}
.table_wrapper table {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #ffffff;
  width: 100%;
  border: 1px solid #e5e7eb;
}
.paddingRight0{
  padding-right: 0px !important;
}
.maxWidth101{
  max-width: 101% !important;
}
.table_wrapper table thead {
  position: sticky;
  top: -1px;
  z-index: 999;
  width: 100%;
  background: #e0e0e0;
}

.table_wrapper table th {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  color: #000;
  position: relative;
  padding: 10px 16px;
  border-bottom: 1px solid #e5e7eb;
  border-top: unset;
  // width: 100%;
}
.marginR-4{
  margin-right: -4px;
}
.table_wrapper table td {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #1c1d1f;
  border-bottom: 1px solid #e5e7eb;
  padding: 10px 16px;
  border-top: unset;
}
.table_wrapper table tr.selectRow {
  background-color: #fff2f2;
}
.table_wrapper table tr.selectRow td {
  background-color: #fff2f2;
}
.table_wrapper table tr:has(button) td {
  padding: 8px 16px;
}

.table_wrapper table tr:last-child td {
  border-bottom: unset;
}
.table_wrapper table tbody tr:hover {
  background-color: #fff2f2 !important;
}
.table_wrapper table tbody tr:hover > .fixedCol {
  background-color: #fff2f2 !important;
}
.table_wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.table_wrapper::-webkit-scrollbar-track {
  background: #dfdfdf;
  border-radius: 0px;
}
.table_wrapper::-webkit-scrollbar-thumb {
  background: #818181;
  border-radius: 10px;
}

//modal wrapper table
.modal_table_wrapper table {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #ffffff;
  width: 100%;
  border: 1px solid #e5e7eb;
}
.modal_table_wrapper table thead {
  position: sticky;
  top: -1px;
  // z-index: 999;
  width: 100%;
  background: #e0e0e0;
}

.modal_table_wrapper table th {
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  color: #000;
  position: relative;
  padding: 10px 16px;
  border-bottom: 1px solid #e5e7eb;
  border-top: unset;
  // width: 100%;
}
.color-picker {
  margin: 10px 0;
}
.modal_table_wrapper table td {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  color: #1c1d1f;
  border-bottom: 1px solid #e5e7eb;
  padding: 10px 16px;
  border-top: unset;
}
.modal_table_wrapper table tr.selectRow {
  background-color: #fff2f2;
}
.modal_table_wrapper table tr.selectRow td {
  background-color: #fff2f2;
}
.modal_table_wrapper table tr:has(button) td {
  padding: 8px 16px;
}
.modal_table_wrapper table tr:last-child td {
  border-bottom: unset;
}
.modal_table_wrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.modal_table_wrapper::-webkit-scrollbar-track {
  background: #dfdfdf;
  border-radius: 0px;
}
.modal_table_wrapper::-webkit-scrollbar-thumb {
  background: #818181;
  border-radius: 10px;
}
.text-right {
  text-align: end;
}
.overflowScroll {
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;

  /* Scrollbar Styling */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #888 #f1f1f1; /* Scroll thumb and track color */
}

/* Webkit Browsers (Chrome, Safari) */
.overflowScroll::-webkit-scrollbar {
  width: 24px; 
  height: 24px; 
}

.overflowScroll::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 6px;
}

.overflowScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.overflowScroll::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
/* Scrollbar for vertical scrolling */
.showTenRows {
  max-height: 600px;
  overflow-y: auto;

  /* Scrollbar Styling */
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.showTenRows::-webkit-scrollbar {
  width: 24px;
}

.showTenRows::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.showTenRows::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.showTenRows::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.customSelect .css-13cymwt-control {
  border-color: #dfe1e6;
  min-height: 36px !important;
  height: 36px;
  border-radius: 3px;
  border-width: 2px;
}
.customSelect .css-13cymwt-control:hover {
  border-color: unset !important;
}

.customSelect .css-1jqq78o-placeholder {
  color: #6d6f82;
  margin: 0px !important;
}

.fixedCol {
  position: sticky !important;
  background-color: #fff;
}

.fixedColH {
  position: sticky !important;
  background-color: #e0e0e0;
  z-index: 999; 
}

.col_no {
  left: 0px;
}
.col_Firstname {
  left: 40px;
}
.col_index {
  left: 40px;
}
.col_trackingId {
  left: 80px;
}
.col_invoiceId {
  left: 170px;
}
.col_cusAcc {
  left: 210px;
}
.col_cusNum {
  left: 180px;
}
.col_actions {
  right: -5px;
}
.col_bagactions {
  right: -28px;
}
.borderRight {
  border-right: 1px solid #e5e7eb;
}

.custom-row {
  --bs-gutter-x: 0 rem !important;
}
.filterBtn {
  background-color: rgba(9, 30, 66, 0.04);
  height: 31px;
  // border: 1px solid rgba(9, 30, 66, 0.04);
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  border-radius: 5px;
  padding: 0px 12px;
  color: #42526e;
  border-radius: 3.01px;
}
.filterRouteBtn {
  background-color: rgba(9, 30, 66, 0.04);
  height: 31px;
  // border: 1px solid rgba(9, 30, 66, 0.04);
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  padding: 0px 9px;
}
.activeFilterBtn {
  background-color: #ec171c;
  height: 31px;
  // border: 1px solid rgba(9, 30, 66, 0.04);
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  border-radius: 5px;
  padding: 0px 12px;
  color: #fff;
  border-radius: 3.01px;
}
.filterBtn:hover {
  background-color: #ec171c;
  color: #fff;
}
.filterBtn:hover img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(208deg)
    brightness(106%) contrast(101%);
}
.fileItem {
  border: 1px solid rgba(9, 30, 66, 0.04);
  height: 31px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  padding: 0px 12px;
  color: #42526e;
  border-radius: 3.01px;
}
.fileItem span {
  background-color: rgba(9, 30, 66, 0.04);
  padding: 0px 4px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}
// .th-resizable {
//   cursor: col-resize !important;
// }

// .th-resizable-row {
//   cursor: default !important;
// }

.smallDropdown {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  background-color: transparent;
  border: 1px solid #e5e5ea;
  outline: unset;
  color: #1c1d1f;
  border-radius: 6px;
  height: 28px;
  width: 100%;
  padding: 0px 12px;
}

.marginTop40 {
  margin-top: 40px !important;
}
.marginTop50 {
  margin-top: 50px !important;
}
.autocomplete-container {
  position: relative;
}
.autocomplete-dropdown {
  position: absolute;
  top: 77%;
  left: 45.7%;
  width: 12.15%;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999; /* Adjust the z-index value */
}
.debriefSummary {
  margin-left: 40px !important;
}
.dropdown-item {
  padding: 8px;
  cursor: pointer;
  border-bottom: 1px solid #ddd; /* Optional: Add a border between items */
}
.input-with-selected-items {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  padding: 5px;
  min-height: 30px; /* Adjust the minimum height as needed */
}

.selected-item {
  background-color: #e0e0e0;
  border-radius: 3px;
  margin: 2px;
  padding: 3px 8px;
}

.remove-button {
  margin-left: 5px;
  cursor: pointer;
}
.dropdown-item:last-child {
  border-bottom: none; /* Optional: Remove the border for the last item */
}
.smallCheckbox {
  display: block;
}

.smallCheckbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
}

.smallCheckbox label {
  position: relative;
  left: 0px;
  padding: 0px;
  margin: 0px;
  color: #959595;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  text-transform: unset;
  text-transform: capitalize;
}

.smallCheckbox label:before {
  content: "";
  border: 2px solid #7a869a;
  background-color: transparent;
  border-radius: 5px;
  width: 18px;
  height: 18px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-right: 12px;
}

.smallCheckbox input:checked + label:after {
  content: "";
  background: url("./assets/img/input_check.svg");
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  // top: 16px;
  // left: 14px;
  width: 19px;
  height: 18px;
  // transform: translate(-50%, -50%);
  bottom: 0px;
  background-size: 12px;
  background-position: center;
}

.smallCheckbox input[type="checkbox"]:checked + label {
  color: #1c1d1f;
}

.smallCheckbox input[type="checkbox"]:checked + label:before {
  transform: scale(1);
  background-color: #ec171c;
  border-color: #ec171c;
}
.smallCheckboxs input[type="checkbox"]:checked + label:before {
  transform: scale(1);
  background-color: #ec171c;
  border-color: #ec171c;
}
/* Custom styles for the checkbox */
.custom-checkbox {
  position: relative;
}
.bold-text {
  font-weight: 600 !important;
  font-size: 16px !important;
  color: #000 !important;
}
.actions-th-info {
  font-weight: 600 !important;
  font-size: 16px !important;
}
.actionColor{
  color: #FF6347  ;
}
.actions-info-container {
  font-weight: 700;
  font-size: 20px;
  line-height: 2.5;
  color: #000;
  padding: 12px 16px;
  border-bottom: 1px solid #e5e7eb;
  border: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.custom-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  background-color: white;
  border: 2px solid #ec171c;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  position: relative;
}

.custom-checkbox input[type="checkbox"]:checked {
  background-color: #ec171c;
}

.custom-checkbox input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}


.customPagination ul.pagination {
  border-radius: 5px;
  margin-bottom: 0px;
}
.customPagination ul.pagination li a {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5e7eb;
  border-left: unset;
  font-size: 12px;
  color: #000;
  line-height: 1.5;
  font-weight: 500;
}
.clear-btn {
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}
.inputIconButton {
  position: absolute;
  right: 10px;         /* Align icon inside the input */
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
}

.inputIconButton img {
  width: 20px;         /* Adjust the size of the icon */
  height: 20px;
}
.customPagination ul.pagination li a:hover {
  background-color: #ec171c;
  border: 1px solid #ec171c;
  color: #fff;
}
.customPagination ul.pagination li.active a {
  background-color: #ec171c;
  border: 1px solid #ec171c;
  color: #fff;
}

.customPagination ul.pagination li:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-left: 1px solid #e5e7eb;
}

.customPagination ul.pagination li:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.css-t3ipsp-control:hover,
.css-t3ipsp-control:focus {
  border-color: unset !important;
  box-shadow: unset;
  height: 36px;
}
//dl validation designs
.flexRow {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.alignRight > div {
  margin-left: 20px; /* Adjust as needed for spacing between elements */
}

.smallCheckboxContainer {
  display: flex;
  align-items: center;
}

.margintop0 {
  margin-top: 0; /* Remove top margin to align with checkbox */
}
.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
  margin-bottom: 10px;
}

.icon {
  padding: 10px;
  min-width: 40px;
}
.margin_top10 {
  margin-top: 10px !important;
}
.margin_right10 {
  margin-right: 10px !important;
}
.mbtm_10 {
  margin-bottom: 10px !important;
}
.mb_10 {
  margin-bottom: 20px !important;
}
.mb_22 {
  margin-bottom: 22px !important;
}
.mb_30 {
  margin-bottom: 30px !important;
}
.mb_35 {
  margin-bottom: 35px !important;
}
.margin_top30 {
  margin-top: 30px !important;
}
.margin_top40 {
  margin-top: 40px !important;
}
.margin_top25 {
  margin-top: 25px !important;
}
.margin_top-25{
  margin-top: -25px !important;
}
.mt_35{
  margin-top: 35px !important;
}
.mt_42{
  margin-top: 42px !important;
}
.mt_3{
  margin-top: 3px !important;
}
/* Additional styles for modal enhancements */
.uploadConfirmpopup .modal-content {
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-align: center;
}

.uploadConfirmpopup .modal-body h2,
.uploadConfirmpopup .modal-body h4 {
  margin-bottom: 20px;
  color: #333;
}
.uploadConfirmpopup .modal-body h2 {
  font-size: 20px;
  font-weight: 500;
}
.uploadConfirmpopup .modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-button {
  display: flex;
  align-items: center;
  padding: 3px 10px;
  background-color: #EC171C;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}

.upload-button img {
  margin-right: 10px;
}

.upload-button input[type="file"] {
  display: none;
}
.summary-tables-container {
  display: flex;
  justify-content: space-between; /* Adds space between the tables */
  gap: 20px; /* Adjust the gap between the two tables */
}
.summary-tables-container {
  display: flex;
  gap: 20px; /* Adjust spacing between the tables */
  width: 100%; /* Ensure the container spans the full width */
}
.summary-table table {
  width: 100%; /* Ensure the table spans the full width of its container */
  border-collapse: collapse;
  text-align: center;
}
.summary-table th,
.summary-table td {
  border: 1px solid #ccc;
  padding: 8px;
}
.summary-table thead th {
  background-color: #f4f4f4;
  font-weight: bold;
}
.delivery-header {
  color: green;
  font-weight: bold;
}

.pickup-header {
  color: blue;
  font-weight: bold;
}

.modal-close {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.modal-close:hover {
  background-color: #c82333;
}

.smallGroupInput {
  display: flex;
}

.smallGroupText {
  display: flex;
  align-items: center;
  padding: 0px 12px;
  text-align: center;
  white-space: nowrap;
  background-color: transparent;
  border: 2px solid #dfe1e6;
  border-radius: 3px;
  height: 36px;
}

.smallGroupInput input {
  background-color: transparent;
  border: 2px solid #dfe1e6;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  width: 100%;
  height: 36px;
  border-radius: 3px;
  padding: 0px 12px 0px 0px;
  outline: unset;
  color: #1c1d1f;
}

.smallGroupInput input::placeholder {
  color: #6d6f82;
}

.smallGroupInput > input:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: unset;
}

.smallGroup-prepent .smallGroupText {
  margin-right: -1px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-right: unset;
}
.smallGroup-append .smallGroupText {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  margin-left: -1px;
  border-left: unset;
}

.smallGroupInput:not(.has-validation) > input:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: unset;
}

.whiteFrame {
  padding-top: 50px;
}

.dropdownResult {
  padding: 8px 0px;
  max-width: 100%;
  background: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 240px;
  height: auto;
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 6px;
  position: absolute;
  z-index: 999;
  transform: translateY(10px);
  font-size: 12px;
}
.dropdownResultItem {
  padding: 8px 12px;
  cursor: pointer;
  text-transform: capitalize;
  border-left: 3px solid transparent;
  font-size: 12px;
}

.dropdownResultItem:hover {
  background-color: #f4f5f7;
  color: #091e42;
  border-left: 3px solid #0052cc;
  margin-left: 0px;
  margin-right: 0px;
}

.seletDropdownIcOn {
  // rgba(9, 30, 66, 0.04)
  background: url("./assets/img/select_downarrow.svg") no-repeat;
  background-size: 12px;
  background-position: calc(100% - 9px) 13.5px !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;

  font-size: 12px;
  line-height: 1.5;
  // font-weight: 500;
  // border: 1px solid transparent;
  border: 2px solid #dfe1e6;
  outline: unset;
  // color: #42526E;
  border-radius: 3px;
  height: 36px;
  width: 100%;
  padding: 0px 12px;
}
.seletDropdownIcOn::placeholder {
  color: #42526e;
}
.seletDropdownIcOn:hover input::placeholder,
.seletDropdownIcOn:focus input::placeholder {
  background-color: #253858;
  color: #fff;
}

.seletDropdownIcOnPage {
  background: rgba(9, 30, 66, 0.04) url("./assets/img/select_downarrow.svg")
    no-repeat;
  background-size: 12px;
  background-position: calc(100% - 9px) 12px !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  border: 1px solid transparent;
  outline: unset;
  color: #42526e;
  border-radius: 5px;
  height: 30px;
  width: 75px;
  padding: 0px 12px;
  cursor: pointer;
}
.seletDropdownIcOnPage::placeholder {
  color: #42526e;
}
.seletDropdownIcOnPage:hover input::placeholder,
.seletDropdownIcOnPage:focus input::placeholder {
  background-color: #253858;
  color: #fff;
}

.dropdownResultItemPage {
  padding: 8px 12px;
  cursor: pointer;
  text-transform: capitalize;
  border-left: 3px solid transparent;
  width: 28%;
}
.document-already-uploaded {
  border: 1px solid #ccc;
  padding: 4px;
  border-radius: 5px;
  margin-top: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.document-status {
  font-weight: bold;
  color: #399918;
  font-size: 14px;
  margin-bottom: 10px;
}

.document-name {
  font-size: 16px;
  color: #333;
}

/* Add hover effect */
.document-already-uploaded:hover {
  border-color: #EC171C; /* Change border color on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.update-document-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.update-document-button:hover {
  background-color: #0056b3;
}
.dropdownResultItemPage:hover {
  background-color: #f4f5f7;
  color: #091e42;
  border-left: 3px solid #0052cc;
  margin-left: 0px;
  margin-right: 0px;
}

.addnewpopupinvoice .modal-dialog {
  max-width: 1050px !important;
}
.addnewpopupRoutes .modal-dialog {
  max-width: 1150px !important;
}
.addnewpopupRevenue .modal-dialog {
  max-width: 1350px !important;
}
.addnewpopupPickup .modal-dialog {
  max-width: 1550px !important;
}
.addnewpopupNpr .modal-dialog {
  max-width: 1050px !important;
}
.addnewpopuphub .modal-dialog {
  max-width: 850px !important;
}
.addnewvehcilepop .modal-dialog {
  max-width: 950px !important;
}
.addnewpopupCus .modal-dialog {
  max-width: 850px !important;
}
.addnewservicearea .modal-dialog {
  max-width: 460px !important;
}
.addnewtracking .modal-dialog {
  max-width: 360px !important;
}
.addtiers .modal-dialog {
  max-width: 480px !important;
}
.addnewpopup .modal-dialog {
  max-width: 720px !important;
}
.addnnewWeighing .modal-dialog {
  max-width: 620px !important;
}
.addVehicleCateg .modal-dialog {
  max-width: 500px !important;
}
.addVat .modal-dialog {
  max-width: 550px !important;
}
.addVehicleDoc .modal-dialog {
  max-width: 600px !important;
}
.rightPopup .modal-dialog {
  max-width: 460px !important;
  margin-right: unset !important;
}
.manageQuickActionpopup .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.declaredValuePopup .modal-dialog {
  max-width: 540px !important;
  margin-right: unset !important;
  height: 100vh;
}
.multipleShipmentpopup .modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 550px;
}
.fullScreenPopup .modal-dialog {
  // max-width: 1797px;
  // max-width: 95%;
  // max-width: 85%;
  max-width: 75%;
}
.mediumScreenPopup .modal-dialog {
  // max-width: 1272px;
  max-width: 900px;
}

.alertPopup .modal-dialog {
  max-width: 295px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.saveChangesPopup .modal-dialog {
  max-width: 540px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.trackingNo_popup .modal-dialog {
  max-width: 700px !important;
}
.trackingNo_popup .modal-dialog {
  max-width: 730px !important;
}
.fullScreenPopup .modal-dialog {
  max-width: 100%;
}

.Mainbutton {
  font-size: inherit;
  line-height: 1.5;
  font-weight: 500;
  color: #fff;
  background: #d30b24;
  border: 1px solid #d30b24;
  border-radius: 3px;
  height: 32px;
  padding: 0px 10px;
}

.Mainbutton:hover {
  color: #d30b24;
  background: transparent;
  border: 1px solid #d30b24;
}
.custom-file-upload input[type="file"] {
  display: none;
}
.date-picker-wrapper {
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
}

.upload-label {
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 10px 15px;
  border-radius: 5px;
  width: 280px;
  cursor: pointer;
}
.upload-labelPickup {
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 10px 15px;
  border-radius: 5px;
  width: 225px;
  cursor: pointer;
}
.upload-labeldriver {
  border: 1px solid #fff;
  background-color: #f9f9f9;
  padding: 10px 15px;
  border-radius: 5px;
  width: 215px;
  cursor: pointer;
}
.file-info {
  margin-top: 10px;
}

.file-name {
  font-size: 14px;
  color: #333;
  font-weight: 450;
  margin: 0;
}
.file-name {
  font-size: 14px;
  color: #333;
  font-weight: 450;
  margin: 0;
}
.upload-label:hover {
  background-color: #fff2f2;
}
.MainHoverbutton {
  color: #d30b24;
  background: transparent;
  border: 1px solid #d30b24;
  border-radius: 5px;
  height: 36px;
  padding: 0px 14px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
}
.MainHoverbutton:hover {
  color: #fff;
  background: #d30b24;
  border: 1px solid #d30b24;
}

.showPerItem {
  padding: 4px 12px;
  cursor: pointer;
  text-transform: capitalize;
  border-left: 3px solid transparent;
}

.showPerItem:hover {
  background-color: #f4f5f7;
  color: #091e42;
  border-left: 3px solid #0052cc;
  margin-left: 0px;
  margin-right: 0px;
}

.requiredText {
  font-family: var(--fontFamily);
  font-size: 12px;
  line-height: 1.5;
  font-weight: 600;
  color: #f44336;
}

.washMe_btn {
  background-color: #ffffff;
  border-color: #909497;
  color: #091e42;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  padding: 0px 6px;
  height: 36px;
  outline: unset;
  width: 100%;
}
.washMe_btn:focus-within:not([data-disabled]) {
  background-color: var(--ds-background-input-pressed, #ffffff);
  border-color: var(--ds-border-focused, #EC171C);
  border: 2px solid #EC171C;
}

.washMe_button {
  background-color: #fff;
  border-color: #909497;
  color: #091e42;
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  padding: 0px 6px;
  height: 45px; 
  outline: unset;
  width: 100%;
}
.washMe_button:focus-within:not([data-disabled]) {
  background-color: var(--ds-background-input-pressed, #ffffff);
  border: 2px solid #EC171C;
  border-color: var(--ds-border-focused, #EC171C);
}
.activeBorderColor {
  border-color: var(--ds-border-focused, #EC171C);
}
.no-arrow-select {
  /* Remove default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Add custom arrow if needed */
  background-image: none;
  /* Optionally, add other styles as needed */
}
.modelScroll {
  max-height: 450px;
  overflow: auto;
}
/* width */
.modelScroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #f4f5f7;
  border-radius: 0px;
}

/* Handle */
.modelScroll::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 30px;
}

/* Handle on hover */
.modelScroll::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1;
}

.modelScrollinvoice {
  max-height: 450px;
  min-height: 525px !important;
  overflow: auto;
}

.modelScrollhub {
  max-height: 450px;
  min-height: 525px !important;
  overflow: auto;
}
.modelScrollDriver {
  max-height: 450px;
  min-height: 460px !important;
  overflow: auto;
}
.w213{
  width: 213px !important;
}
.w218{
  width: 254px !important;
}
.w500{
  width: 500px !important;
}
.w200{
  width: 200px !important;
}
.orderRangeDate{
  width: 200px !important;
  z-index: 9999 !important;
}
.rs-picker-menu {
  z-index: 9999 !important;
  position: absolute !important;
}
.w20per{
  width: 20% !important;
}
.modelScrollRoute {
  max-height: 450px;
  min-height: 570px !important;
  overflow: auto;
}
.modelScrollWt {
  max-height: 350px;
  min-height: 330px !important;
  overflow: auto;
}
.modelScrollAcl {
  max-height: 350px;
  min-height: 400px !important;
  overflow: auto;
}
.modelScrollAddress {
  max-height: 350px;
  min-height: 425px !important;
  overflow: auto;
}
.modelScrollWeight {
  max-height: 350px;
  min-height: 460px !important;
  overflow: auto;
}
.modelScrollWtVehcile {
  max-height: 350px;
  min-height: 700px !important;
  overflow: auto;
}
.modelScrollRevenue {
  max-height: 350px;
  min-height: 870px !important;
  overflow: auto;
}
.modelScrollVehicle {
  max-height: 450px;
  min-height: 240px !important;
  overflow: auto;
}
.modelScrollVat {
  max-height: 460px;
  min-height: 220px !important;
  overflow: auto;
}
.modelScrollVt {
  max-height: 460px;
  min-height: 200px !important;
  overflow: auto;
}
.modelScrollGpa {
  max-height: 480px;
  min-height: 220px !important;
  overflow: auto;
}
.modelScrollReset {
  max-height: 450px;
  min-height: 170px !important;
  overflow: auto;
}
.modelScrollRst {
  max-height: 450px;
  min-height: 110px !important;
  overflow: auto;
}
.modelScrollTrack {
  max-height: 450px;
  min-height: 80px !important;
  overflow: auto;
}
.modelScrollVehiclespopup {
  max-height: 450px;
  min-height: 520px !important;
  overflow: auto;
}
.modelScrollorderPickpopup {
  max-height: 450px;
  min-height: 660px !important;
  overflow: auto;
}
.scanned-packages {
  background-color: #f8f9fa; /* Light gray background */
  border: 1px solid #ced4da; /* Border color */
  border-radius: 5px;
  padding: 10px;
  max-height: 200px; /* Limit height for scrolling */
  overflow-y: auto; /* Scrollable for long lists */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}
.scanned-package {
  padding: 8px;
  margin: 5px 0;
  background-color: #ffffff; /* White background for each package */
  border: 1px solid #dee2e6; /* Light border */
  border-radius: 3px;
  font-size: 14px;
  font-family: Arial, sans-serif;
  color: #495057; /* Text color */
  word-break: break-word; /* Handle long IDs gracefully */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.scanned-package:hover {
  transform: translateY(-2px); /* Slight lift on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}
.modelScrollVehicleVendors {
  max-height: 450px;
  min-height: 25px !important;
  overflow: auto;
}
.modelScrollVehicleDoc {
  max-height: 350px;
  min-height: 320px !important;
  overflow: auto;
}
.modelScrollVehicleDocument {
  max-height: 350px;
  min-height: 600px !important;
  overflow: auto;
}
.modelScrollthirdparty {
  max-height: 450px;
  min-height: 650px !important;
  overflow: auto;
}
.modelScrollOda {
  max-height: 350px;
  min-height: 450px !important;
  overflow: auto;
}
.modelScrollCus {
  max-height: 450px;
  min-height: 760px !important;
  overflow: auto;
}
.modelScrollTp {
  max-height: 450px;
  min-height: 700px !important;
  overflow: auto;
}
.mainInput-dropdown, .mainInput-dropdown .your-dropdown-class {
  z-index: 1050 !important; /* Ensures this rule takes precedence */
}

.jira-dropdown {
  z-index: 1000; /* Lower than the dropdown to ensure it appears beneath */
}
.mt_60{
  margin-top: 60px;
}
.mt_55{
  margin-top: 55px;
}
.modelScrollFuel {
  max-height: 450px;
  min-height: 500px !important;
  overflow: auto;
}
.modelScrollOda {
  max-height: 450px;
  min-height: 520px !important;
  overflow: auto;
}
.modelScrollNpr {
  max-height: 450px;
  min-height: 520px !important;
  overflow: auto;
}
.modelScrollNdr {
  max-height: 450px;
  min-height: 620px !important;
  overflow: auto;
}
/* width */
.modelScrollNpr::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #f4f5f7;
  border-radius: 0px;
}
/* width */
.modelScrollinvoice::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #f4f5f7;
  border-radius: 0px;
}

/* Handle */
.modelScrollinvoice::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 30px;
}

/* Handle on hover */
.modelScrollinvoice::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1;
}

.modelScrollTwo {
  height: 200px;
  overflow: auto;
}

/* width */
.modelScrollTwo::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #f4f5f7;
  border-radius: 0px;
}

/* Handle */
.modelScrollTwo::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 30px;
}

/* Handle on hover */
.modelScroll::-webkit-scrollbar-thumb:hover {
  background: #c1c1c1;
}

.borderConsignment {
  border: 3px solid #e5e5ea;
  border-radius: 5px;
  padding: 5px;
  margin-top: 20px;
}

.spaceConsignment {
  padding: 20px;
}

.consignmentcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.consignmentend {
  display: flex;
  justify-content: end;
  align-items: end;
}
.smallGroupInputcon input {
  background-color: transparent;
  border: 2px solid #dfe1e6;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  width: 100%;
  height: 36px;
  border-radius: 3px;
  padding: 0px 12px 0px 0px;
  outline: unset;
  color: #1c1d1f;
}

.smallGroupInputcon input::placeholder {
  color: #6d6f82;
}

.smallGroupInputcon > input:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: unset;
}

.smallGroupInputcon {
  display: flex;
}

.orderDetailsTitle {
  width: 95px;
  background: white;
  position: relative;
  top: 30px;
  left: 20px;
  padding: 2px;
}

.singleBtn {
  width: 70px;
  background: white;
  position: relative;
  top: 66px;
  left: 20px;
  padding: 2px;
}

.bulkBtn {
  width: 56px;
  background: white;
  position: relative;
  top: 30px;
  left: 100px;
  padding: 2px;
}
.hrconsign {
  border: black 2px solid !important;
  color: #000 !important;
}

.filbtnSelected {
  background-color: #253858;
  color: #fff;
  height: 31px;
  // border: 1px solid rgba(9, 30, 66, 0.04);
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  border-radius: 5px;
  padding: 0px 12px;
  border-radius: 3.01px;
}

.bulkListDiv {
  border: 3px solid #e5e5ea;
  border-radius: 5px;
  padding: 5px;
  margin-top: 20px;
  width: 20%;
  height: 100%;
}
.receiverListDiv {
  border: 2px solid #e5e5ea;
  border-radius: 5px;
  padding: 5px;
  height: 350px;
  overflow-y: auto;
}
.receiverListDiv::-webkit-scrollbar {
  width: 5px;
}

.receiverListDiv::-webkit-scrollbar-track {
  background: #e5e7eb;
}

.receiverListDiv::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.receiverListDiv::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.messageDiv {
  border: 3px solid #e5e5ea;
  border-radius: 5px;
  padding: 5px;
  margin-top: 20px;
  width: 60%;
  height: 80%;
}

.messageArea {
  font-family: "Poppins", sans-serif !important;
  border: 3px solid #e5e5ea;
  border-radius: 3px;
}
.customTab .nav-tabs {
  border-width: 1px;
  border-color: #e5e5ea;
  gap: 12px;
}
.customTab .nav-tabs .nav-link {
  margin-bottom: -1px;
  border-width: 1px;
  background: transparent;
  color: #000;
}
.customTab .nav-link.active {
  background-color: #e5e5ea !important;
  color: #000 !important;
  border: 1px solid #e5e5ea !important;
  border-bottom: unset !important;
}
.customTab .tab-content {
  border: 1px solid #e5e5ea;
  border-top: unset;
  padding: 16px;
}
.borderFramer {
  border: 1px solid #909497;
  padding: 16px;
  padding-bottom: 0px;
  border-radius: 5px;
}
.borderFrame {
  border: 1px solid #909497;
  padding: 16px;
  border-radius: 5px;
}
.borderFrameTitle {
  font-size: 12px;
  line-height: 1.5;
  font-weight: bolder;
  background: #fff;
  /* margin-top: -30px; */
  position: relative;
  top: -28px;
  display: inline-block;
  padding: 0px 6px;
}
.borderFrameTitleOrders {
  font-size: 12px;
  line-height: 1;
  font-weight: bolder;
  background: #fff;
  /* margin-top: -30px; */
  position: relative;
  top: -28px;
  display: inline-block;
  padding: 0px 6px;
}
.mainInput {
  background-color: transparent;
  border: 1px solid #909497;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  width: 100%;
  height: 38px;
  border-radius: 5px;
  padding: 0px 12px;
  outline: unset;
  color: #1c1d1f;
}
.mainInput.disabled {
  background-color: #f0f0f0;
}
.widerDatePicker {
  width: 300px; /* Adjust the width as needed */
}
.marginR65{
  margin-right: 75px;
}
.mainInputOrder {
  background-color: transparent;
  border: 1px solid #909497;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  width: 100%;
  height: 38px;
  border-radius: 5px;
  padding: 0px 12px;
  outline: unset;
  color: #1c1d1f;
}
.mainPara {
  font-size: 13px;
  line-height: 2.5;
  font-weight: 400;
  width: 100%;
  height: 38px;
  padding: 0px 20px;
  color: #1c1d1f;
}
.custom-radio-container {
  display: flex;
  gap: 20px; /* Adjust the gap as needed */
}

.custom-radio-input:checked + .custom-radio-label::before {
  border-color: #EC171C;;
}

.custom-radio-input:checked + .custom-radio-label::after {
  background-color: white;
  border: 4px solid #EC171C;;
}

.custom-radio-input {
  display: none;
}

.custom-radio-label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}
.DbStart {
  display: flex;
  width: 100%;
  margin-left: -5px;
}
.totals {
  display: flex;
  width: 100%;
  gap: 10px; /* Space between the total elements */
  margin-left: 10px; /* Space between the button and totals */
}
.ml0{
  margin-left: 0px !important;
}
.totalCourier {
  display: flex;
  margin-left: 20px;
}
.totalsTransaction {
  margin-left: auto;
}
.totals span {
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}
.totals button {
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: bold;
}
.total-count {
  background-color: #f0f0f0;
  color: #333;
}

.total-success {
  background-color: #d4edda;
  color: #155724;
}
.total-remain {
  background-color: #FFF9C4;
  color: #333333; 
}
.total-assign {
  background-color: #CDF5FD;
  color: #362FD9;
}
.total-successSms {
  background-color: #d4edda;
  color: #155724;
  border-radius: 5px;
  font-weight: bold;
}
.total-courier {
  background-color: #F1EAFF;
  color: #6420AA;
}

.total-failure {
  background-color: #f8d7da;
  color: #721c24;
}
.total-failureSms {
  background-color: #f8d7da;
  color: #721c24;
  border-radius: 5px;
  font-weight: bold;
}
.custom-radio-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: white;
  transition: border-color 0.2s;
}

.custom-radio-label::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
  transition: background-color 0.2s, border 0.2s;
}

.orderDetail_scroll {
  height: 570px;
  overflow-y: auto;
  overflow-x: hidden;
}
.orderDetail_scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */

.orderDetail_scroll::-webkit-scrollbar-track {
  background: #dfdfdf;
  border-radius: 0px;
}

/* Handle */

.orderDetail_scroll::-webkit-scrollbar-thumb {
  background: #818181;
  border-radius: 10px;
}

.appearTop {
  position: absolute;
  top: -150px;
}

.headManage {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.blackbtn {
  padding: 5px 10px;
  border-radius: 5px;
  background: #040505;
  color: #ffffff;
  outline: unset;
  border: 1px solid #040505;
  height: 40px;
}
.blackbtn:hover {
  background: #ffffff;
  color: #040505;
  border: 1px solid #040505;
}
.inputsearch {
  border: 1px solid #cccccc;
  height: 36px;
  border-right: 0px;
  border-radius: 10px;
  padding: 0px 5px 0px 12px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: unset;
  display: flex;
  align-items: center;
}
.mainheadtext {
  font-size: 20px;
}

.red_outlinebtn {
  border: 1px solid #ec171c;
  border-radius: 5px;
  background: none;
  color: #ec171c;
  padding: 5px 10px;
}
.red_outlinebtn:hover {
  background: #ec171c;
  color: #ffffff;
}
.red_outlinebtn:hover img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(208deg)
    brightness(106%) contrast(101%);
}
.black_outlinebtn {
  border: 1px solid #040505;
  border-radius: 5px;
  background: none;
  color: #040505;
  padding: 5px 10px;
}
.black_outlinebtn:hover {
  background: #040505;
  color: #ffffff;
}
.black_outlinebtn:hover img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(208deg)
    brightness(106%) contrast(101%);
}
.red_btn {
  border: 1px solid #ec171c;
  border-radius: 5px;
  background: #ec171c;
  color: #ffffff;
  padding: 5px 10px;
}
.red_btn:hover {
  border: 1px solid #ec171c;
  border-radius: 5px;
  background: none;
  color: #ec171c;
  padding: 5px 10px;
}
.blue_btn {
  border: 1px solid #ec171c;
  border-radius: 5px;
  background: #ec171c;
  color: #ffffff;
  padding: 5px 10px;
}
.blue_btn:hover {
  border: 1px solid #ec171c;
  border-radius: 5px;
  background: #ec171c;
  color: #ffffff;
  padding: 5px 10px;
}
.cancel_btn {
  border: unset;
  color: #6b778c;
  background: none;
  font-weight: 500;
  padding: 5px 10px;
}
.cancel_btn:hover {
  border: unset;
  color: #8993a4;
  text-decoration: underline;
  background: none;
  font-weight: 600;
  padding: 5px 10px;
  font-size: 14px !important;
}
.cancel_btnRto {
  border: unset;
  color: #6b778c;
  background: none;
  font-weight: 500;
  padding: 5px 10px;
}
.cancel_btnRto:hover {
  border: unset;
  color: #8993a4;
  text-decoration: underline;
  background: none;
  font-weight: 600;
  padding: 5px 10px;
}
.higher-z-index {
  z-index: 1002; /* You can adjust the value as needed */
}
.dropdown-container {
  position: relative;
  z-index: 1001; /* You can adjust the value as needed */
}
.dropdown-containers {
  position: relative;
  z-index: 1003; /* You can adjust the value as needed */
}
.cancelinv_btn {
  border: unset;
  color: #6b778c;
  background: none;
  font-weight: 600;
  padding: 5px 10px;
}
.cancelinv_btn:hover {
  border: unset;
  color: #8993a4;
  text-decoration: underline;
  background: none;
  font-weight: 600;
  padding: 5px 10px;
}
.mt_70 {
  margin-top: 70px !important;
}
.mr_8 {
  margin-right: 8px;
}
.mr_3 {
  margin-right: -3px !important;
}
.border_none {
  border: unset !important;
  justify-content: unset !important;
  padding-top: 0 !important;
}
.AsyncPaginate__menu {
  border-color: red !important; /* Change border color */
}

.AsyncPaginate__option--is-selected {
  background-color: red !important; /* Change selected item background color */
  color: white !important; /* Change selected item text color */
}
.status_active {
  padding-left: 5px;
  border-radius: 40px;
  color: green;
  text-align: left;
  margin-bottom: 0;
  position: relative;
}
.success_active {
  padding-left: 5px;
  border-radius: 40px;
  color: green;
  text-align: left;
  margin-bottom: 0;
  position: relative;
}
.status_active::before {
  width: 8px;
  height: 8px;
  background: green;
  border-radius: 50%;
  position: absolute;
  content: "";
  left: -10%;
  top: 25%;
}

.status_inactive {
  padding-left: 5px;
  border-radius: 40px;
  color: #cf141a;
  text-align: left;
  margin-bottom: 0;
  position: relative;
}
.success_inactive {
  padding-left: 5px;
  border-radius: 40px;
  color: #cf141a;
  text-align: left;
  margin-bottom: 0;
  position: relative;
}
.customerContainer {
  position: absolute;
  z-index: auto;
  border-radius: 5px;
  width: 39%; /* Set width same as the input */
  max-height: 200px; /* Set a maximum height or adjust as needed */
  overflow-y: auto; /* Enable vertical scrollbar if content exceeds max-height */
  border: 1px solid #ccc; /* Add a border for visual separation */
  background-color: #fff; /* Set a background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  display: grid;
  grid-template-rows: repeat(10, 1fr);
}
.customerContainerFlow {
  position: absolute;
  z-index: auto;
  border-radius: 5px;
  width: 16%; 
  max-height: 200px; 
  overflow-y: auto; 
  border: 1px solid #ccc; 
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-rows: repeat(10, 1fr);
}
.customerContainerItem {
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.customerContainerItem:hover {
  background-color: #fff2f2;
}
.customerContainerItem.selected {
  background-color: #ec171c; /* Change this to the desired background color */
  color: #ffffff; /* Change this to the desired text color */
}
.status_inactive::before {
  width: 8px;
  height: 8px;
  background: #cf141a;
  border-radius: 50%;
  position: absolute;
  content: "";
  left: -10%;
  top: 25%;
}
.status_pending {
  padding-left: 5px;
  border-radius: 40px;
  color: #ffaf36;
  text-align: left;
  margin-bottom: 0;
  position: relative;
}
.status_pending::before {
  width: 8px;
  height: 8px;
  background: #ffaf36;
  border-radius: 50%;
  position: absolute;
  content: "";
  left: -10%;
  top: 25%;
}
.status_process {
  padding-left: 5px;
  border-radius: 40px;
  color: #4169e1;
  text-align: left;
  margin-bottom: 0;
  position: relative;
}
.status_process::before {
  width: 8px;
  height: 8px;
  background: #4169e1;
  border-radius: 50%;
  position: absolute;
  content: "";
  left: -10%;
  top: 25%;
}
.tabletab {
  border: 1px solid #e5e7eb;
  padding: 5px 15px;
  border-radius: 40px;
  border-bottom: 3px solid #e5e7eb;
  cursor: pointer;
}
.tabletab:hover {
  border-bottom: 3px solid #ec171c;
  color: #ec171c;
}
.tabletab_section {
  gap: 10px;
}
.tabletabactive {
  border-bottom: 3px solid #ec171c;
  color: #ec171c;
}
//toggle design
.toggle-switch-container {
  display: flex;
  align-items: center;
}
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px; /* Adjust width to make the toggle smaller */
  height: 20px; /* Adjust height to make the toggle smaller */
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 20px; /* Adjust border radius accordingly */
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px; /* Adjust height of the toggle button */
  width: 16px; /* Adjust width of the toggle button */
  left: 2px; /* Adjust left position */
  bottom: 2px; /* Adjust bottom position */
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input:checked + .slider {
  background-color: #EC171C;
}

input:focus + .slider {
  box-shadow: 0 0 1px #EC171C;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(20px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-label {
  margin-left: 10px;
}
.mt_10 {
  margin-top: 10px !important;
}
.mtop20{
  margin-top: 20px !important;
}
.mt_6pix{
  margin-top: 6px !important;
}
.mt_16{
  margin-top: 16px !important;
}
.mt_6 {
  margin-top: 6.5px !important;
}
.mt_55 {
  margin-top: 55px !important;
}
.error-message {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px; /* Adjust the margin as needed */
}

.error-message p {
  background-color: #f44336; /* A softer shade of red */
  color: #fff; /* White text for better contrast */
  padding: 8px 16px; /* More padding for a comfortable look */
  border-radius: 8px; /* Slightly more rounded corners */
  display: inline-flex;
  align-items: center; /* Vertically center the text and icon */
  font-size: 14px; /* Slightly larger font size for readability */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
}

.error-message p .error-icon {
  background-color: #fff;
  color: #f44336;
  font-weight: bold;
  border-radius: 50%;
  padding: 2px 8px;
  margin-right: 8px; /* Adjust the spacing between the icon and the text */
  display: inline-block;
}
.error-message p span {
  font-size: 16px; /* Larger font for the icon */
}
.error-message p::before {
  content: '!';
  background-color: #fff;
  color: #f44336;
  font-weight: bold;
  border-radius: 50%;
  padding: 2px 8px;
  font-size: 18px; /* Slightly larger size for visibility */
  margin-right: 8px; /* Space between the icon and text */
  display: inline-block;
  position: relative;
  top: -1px; /* Slight adjustment to vertically align with text */
}
.withSearchIcon {
  padding-left: 25px;
  background: url("./assets/img/searchicon.svg") no-repeat left center;
  background-size: 35px 18px;
}
.withSearchIcon:focus {
  border: 2px solid #EC171C; /* Add border when focused */
}
.displaynone {
  display: none;
}
.mainheadlink {
  font-size: 16px;
  color: #777777;
  margin: 0 5px;
  cursor: pointer;
}
.mainheadlink:hover {
  border-bottom: 1px solid #777777;
}
.pt30 {
  padding-top: 30px;
}
.pt5 {
  padding-top: 5px;
}
.custom-date-picker {
  width: 200px; /* Set the width as needed */
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  outline: none;
  /* Add more styles based on your design */
}
.custom-opsdate-picker {
  width: 225px; /* Set the width as needed */
  margin-top: 35px;
  margin-left: -40px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  outline: none;
}
/* Styling for the datepicker calendar */
.react-datepicker {
  font-size: 14px;
}

/* Styling for the selected date */
.react-datepicker__day--selected {
  background-color: #ec171c !important; /* Set your desired color */
  color: #fff;
}

/* Styling for the current month and year in the calendar */
.react-datepicker__current-month,
.react-datepicker-year-header {
  color: #333;
  font-weight: bold;
}

/* Styling for the header of the calendar */
.react-datepicker__header {
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}
.bg_grey {
  // background: #E0E0E0;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
  color: #040505;
}
.tabshowcontent {
  border: 1px solid #cccccc;
  height: 300px;
  width: 100%;
  border-radius: 15px;
  margin-top: 15px;
}
.text_red li button {
  color: #0052cc;
}

.tab-content {
  border: 1px solid #dee2e6 !important;
  // min-height: 300px;
  padding: 24px;
  // padding-top: unset;
  border-top: unset !important;
}

.lastEditManage div {
  inset: auto !important;
  right: 50px !important;
}

.detailedWhite table th {
  background-color: #fff !important;
  border-bottom: 0 !important;
}

.detailedWhite table {
  box-shadow: none !important;
}

.actionInputHandler {
  width: -webkit-fill-available;
  border: 0;
  outline: none;
  padding: 5px;
}

.turnBlack {
  filter: grayscale(1);
}

.h18 {
  height: 18px;
}
.mt_20{
  margin-top: 20px;
}
.mb5{
  margin-bottom: 5px;
}
.mt_-50{
  margin-top: -50px;
}
// .InputGroup {
//   border: 1px solid red;
//   border-left: none;
// }
.InputGroup:has(:focus) input {
  border: 2px solid #d30b24;
  border-left: unset;
}
.InputGroup:has(:focus) .inputsearch {
  border: 2px solid #d30b24;
  border-right: unset;
}
.InputGroup:has(:focus) img {
  filter: grayscale(0);
}

.p12_washme {
  padding: 0px 12px !important;
}

.col-md-5 .dropdownResultItem:hover {
  border-left: 3px solid #EC171C;
}

.plus_icon {
  font-size: 22px !important;
}
.plusRoute_icon {
  font-size: 14px !important;
}
.tableBtn {
  background-color: transparent;
  height: 26px;
  // border: 1px solid rgba(9, 30, 66, 0.04);
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  border-radius: 5px;
  padding: 0px 8px;
  color: #42526e;
  border-radius: 3.01px;
}

.tableBtn:hover {
  background-color: rgba(9, 30, 66, 0.04);
}

button {
  font-size: 12px !important;
}

// .admin_sidebar ul li::after{
//   display: none;
//   font-size: x-small;
//   padding: 2px 4px;
//   color: white;
//   background-color: #000;
//   border: 1px solid white;
//   position: absolute;
//   content: attr(title);
//   transition: all ease-in;
// }

// .admin_sidebar ul li:hover:after{
//   display: block;
// }
.customTabGroup {
  display: flex;
  align-items: stretch;
  gap: 12px;
  height: calc(100% - 112px);
}
.customTabGroup > div {
  width: 250px;
}
.customTabGroup > div:nth-child(2) {
  width: calc(100% - 250px);
  position: relative;
}
.position_Relative {
  position: relative;
}
.nthChildWidth100per > :nth-child(1) {
  width: 100% !important;
}
.customTabGroupSidebar {
  border: 1px solid #bcc3c7;
  padding: 14px 12px;
  border-radius: 10px;
  // height: 400px;
}
.customTabGroupSidebar ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.customTabGroupSidebar ul li {
  padding: 8px 12px;
  line-height: 1.5;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
}
.customTabGroupSidebar ul li:where(:hover, .active) {
  background-color: #e6effc;
  color: #0052cc;
}
.customTabGroupSidebar ul li.active {
  position: relative;
}
.customTabGroupSidebar ul li.active::before {
  content: "";
  position: absolute;
  height: 16px;
  width: 4px;
  left: 2px;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #0052cc;
  border-radius: 0px 1.998px 1.998px 0px;
}
.customMainInputbasedInput .select__placeholder {
  color: var(--PinballColor);
  font-size: 12px;
  font-weight: 500;
}
.customMainInputbasedInput .select__control--is-disabled {
  border-radius: 7px;
  background-color: var(--whiteColor);
  // border-color:rgb(28, 29, 31);
}

.customMainInputbasedInput .select__input-container {
  font-size: 12px;
  font-weight: 500;
  color: var(--satinDeepblackColor) !important;
}
.customMainInputbasedInput .select__menu {
  font-size: 12px;
  font-weight: 500;
}
.customMainInputbasedInput .select__single-value {
  color: black;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
}
.customMainInputbasedInput .select__control {
  min-height: 44px !important;
}
.customMainInputbasedInputScroll .select__menu {
  overflow-y: scroll;
  height: 110px;
}
// customMainInputbasedInputScroll start

.customMainInputbasedInputScroll .select__menu::-webkit-scrollbar {
  width: 7px;
  height: 6px;
  border-radius: 5px;
}

/* Track */
.customMainInputbasedInputScroll .select__menu::-webkit-scrollbar-track {
  background: var(--dreamyCloudColor);
  border-radius: 5px;
}

/* Handle */
.customMainInputbasedInputScroll .select__menu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.customMainInputbasedInputScroll .select__menu::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.subContents {
  display: flex;
  flex-direction: column;
}

.notesContents {
  border: 1px solid #dee2e6;
  padding: 20px 10px;
}

// .paginationFooter{
//   width: 100%;
//   position: absolute;
//   bottom: 0;
// }

.customDiv {
  margin-right: 1px;
}

.deleteAcc_input {
  background-color: transparent;
  border: 2px solid #dfe1e6;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  width: 300px;
  margin: auto;
  display: block;
  height: 36px;
  border-radius: 3px;
  padding: 0px 12px;
  outline: unset;
  color: #1c1d1f;
  text-align: center;
}
// .deleteAcc_input:focus{
//   border: 2px solid red;
// }

.deleteAccpopup .modal-content {
  border-top: 3px solid #ff0041;
  border-radius: 10px !important;
  position: relative;
  width: 380px;
}
.scanConfirmpopup .modal-content {
  border-top: 3px solid #ff0041;
  border-radius: 10px !important;
  position: relative;
  width: 380px;
}
.scanConfirmpopup .modal-body {
  padding-top: 15px;
}
.scanConfirmpopup .modal-footer {
  background: #eeeeee;
  border: unset !important;
  gap: 12px;
}
.scanConfirmpopup .modal-footer > * {
  width: 100%;
  margin: 0px;
}
.scanPayrollpopup .modal-content {
  border-top: 3px solid #ff0041;
  border-radius: 10px !important;
  position: relative;
  width: 500px;
}
.scanPayrollpopup .modal-body {
  padding-top: 15px;
}
.scanPayrollpopup .modal-footer {
  background: #eeeeee;
  border: unset !important;
  gap: 12px;
}
.scanPayrollpopup .modal-footer > * {
  width: 100%;
  margin: 0px;
}
.scanRevenuepopup .modal-content {
  border-top: 3px solid #ff0041;
  border-radius: 10px !important;
  position: relative;
  width: 800px;
}
.scanRevenuepopup .modal-body {
  padding-top: 15px;
}
.scanRevenuepopup .modal-footer {
  background: #eeeeee;
  border: unset !important;
  gap: 12px;
}
.scanRevenuepopup .modal-footer > * {
  width: 100%;
  margin: 0px;
}
.rightPopup .modal-content {
  // border-radius: 45px 0px 0px 45px;
  border-radius: 25px 0px 0px 25px;
  min-height: 100vh;
}
.manageQuickActionpopup .modal-content {
  border-radius: 28px;
}
.declaredValuePopup .modal-content {
  border-radius: 45px 0px 0px 45px;
  min-height: 100%;
}
.multipleShipmentpopup .modal-content {
  border-radius: 28px;
}
.alertPopup .modal-content {
  border-radius: 28px;
}
.saveChangesPopup .modal-content {
  border-radius: 28px;
}
.trackingNo_popup .modal-content {
  border-radius: 23px;
}
.trackingNo_popup .modal-content {
  border-radius: 23px;
}
.deleteAccpopup .modal-body {
  padding-top: 50px;
}
.deleteAccpopup .modal-footer {
  background: #eeeeee;
  border: unset !important;
  gap: 12px;
}
.deleteAccpopup .modal-footer > * {
  width: 100%;
  margin: 0px;
}

.popupheadinglogo {
  position: absolute;
  top: -35px;
  left: 0;
  right: 0;
  width: 70px;
  height: 70px;
  background-color: #ec171c;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 0;
}
.bulkActions {
  position: absolute;
  z-index: 100;
  margin-left: 34px;
}
.bulkActions button {
  display: block;
  border-radius: 3.01px;
  height: 31px;
  line-height: 1.5;
  font-weight: 500;
  margin-bottom: 8px;
  background-color: #fff;
  color: #ec171c;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
}

.bulkActions button:hover {
  background-color: #ec171c;
  color: #fff;
}

.bulksync {
  margin-bottom: 25px;
  display: flex;
  margin-left: 20px;
}
.jira-dropdown {
  position: relative;
  display: inline-block;
  z-index: 1; /* Ensure the dropdown has a higher z-index than other content */
}
.disabled {
  background-color: #f0f0f0; /* Change to the background color you want */
  color: #888; /* Change to the text color you want */
  cursor: not-allowed;
  /* Add any other styling you want to indicate it's disabled */
}
/* Dropdown button */
.jira-dropdown-button {
  background-color: #ec171c;
  color: #fff;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
}

.jira-dropdown-button:hover {
  background-color: #ec171c !important;
}

/* Dropdown content */
.jira-dropdown-content {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 130px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 2; /* Ensure the dropdown content appears above other content */
  top: 40px; /* Adjust the distance from the button */
  cursor: pointer;
}
.jira-dropdown-content li {
  margin: 0;
  padding: 0;
  border-radius: 20px;
}
.jira-dropdown-content a {
  padding: 7px 14px; /* Adjust the padding to reduce the size */
  font-size: 12px; /* Adjust the font size to reduce the size */
  color: #333; /* Text color for dropdown items */
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
  border-radius: 5px;
}

.jira-dropdown-content a:hover {
  display: block;
  border-left: 3px solid #ec171c;
  // border-radius: 0;
  background-color: #fff2f2; /* Background color on hover */
}
.jira-dropdown-contentinvoice {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: none;
  position: absolute;
  background-color: #fff;
  min-width: 130px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  z-index: 2; /* Ensure the dropdown content appears above other content */
  top: 40px; /* Adjust the distance from the button */
  cursor: pointer;
}
.jira-dropdown-contentinvoice li {
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
}

.jira-dropdown-contentinvoice li:hover {
  background-color: #fff2f2;
}

.jira-dropdown-contentinvoice li:last-child {
  border-bottom: none;
}
.jira-dropdown-contentinvoice a {
  padding: 7px 14px; /* Adjust the padding to reduce the size */
  font-size: 12px; /* Adjust the font size to reduce the size */
  color: #333; /* Text color for dropdown items */
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
  border-radius: 5px;
}

.jira-dropdown-contentinvoice a:hover {
  display: block;
  border-left: 3px solid #ec171c;
  // border-radius: 0;
  background-color: #fff2f2; /* Background color on hover */
}
.state-dropdown-content {
  display: none; /* Initially hidden */
  position: absolute; /* Positioning relative to the parent item */
  left: 100%; /* Show to the right of the parent item */
  top: 0;
  z-index: 999;
  cursor: pointer;
  border-radius: 20px;
}
/* FontAwesome caret-down icon */
.jira-dropdown-button i {
  margin-left: 8px;
}
.mb-fil {
  margin-bottom: 0px;
}

.width_200 {
  width: 200px;
  margin-left: 5px;
}

.mleft_5 {
  margin-left: -5px;
}
.mleft_5p {
  margin-left: 9px;
}
.mleft_10 {
  margin-left: 10px;
}
.mleft_20 {
  margin-left: 20px;
}
.ml_25{
  margin-left: 25px !important;
}
.ml_10 {
  margin-left: 10px !important;
}
.ml_42  {
  margin-left: 42% !important;
}
.mr_0 {
  margin-right: 0px !important;
}
.ml_0 {
  margin-left: 0px !important;
}
.mright_50 {
  margin-right: 0px;
}
@media (max-width: 5130px) {
  .InputGroup input {
    width: 90%;
  }
  .debreifAlign{
    margin-left:1000px !important;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .dateTop{
    margin-top: 48px !important;
  }
}
@media (max-width: 3860px) {
  .InputGroup input {
    width: 90%;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .debreifAlign{
    margin-left:820px !important;
  }
  .mtDate {
    margin-top: 50px !important;
  }
  .col-8-percent {
    margin-top: 10px !important;
  }
  .col-20-percent {
    margin-top: 10px !important;
  }
  .col-13-5-percent {
    margin-top: 10px !important;
  }
  .col-14-percent {
    margin-top: 10px !important;
  }
  .dateTop{
    margin-top: 48px !important;
  }
  .mapContainer {
    height: 600px;
    width: 1650px;
    border: 2px solid #000;
    border-radius: 5px;
  }
}
@media (max-width: 3100px) {
  .InputGroup input {
    width: 90%;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .mtDate {
    margin-top: 50px !important;
  }
  .debreifAlign{
    margin-left:800px !important;
  }
  .debriefSummary {
    margin-left: 860px !important;
  }
  .col-8-percent {
    margin-top: 10px !important;
  }
  .col-20-percent {
    margin-top: 10px !important;
  }
  .col-13-5-percent {
    margin-top: 10px !important;
  }
  .col-14-percent {
    margin-top: 10px !important;
  }
  .dateTop{
    margin-top: 48px !important;
  }
  .mapContainer {
    height: 600px;
    width: 1320px;
    border: 2px solid #000;
    border-radius: 5px;
  }
}
@media (max-width: 2880px) {
  .InputGroup input {
    width: 90%;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .mtDate {
    margin-top: 50px !important;
  }
  .debreifAlign{
    margin-left:760px !important;
  }
  .debriefSummary {
    margin-left: 755px !important;
  }
  .col-8-percent {
    margin-top: 10px !important;
  }
  .mapContainer {
    height: 600px;
    width: 1250px; /* Adjust the height to take up a portion of the viewport */
    border: 2px solid #000;
    border-radius: 5px;
  }
  .col-20-percent {
    margin-top: 10px !important;
  }
  .col-13-5-percent {
    margin-top: 10px !important;
  }
  .col-14-percent {
    margin-top: 10px !important;
  }
  .dateTop{
    margin-top: 48px !important;
  }
}
@media (max-width: 2560px) {
  .InputGroup input {
    width: 90%;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .debreifAlign{
    margin-left:730px !important;
  }
  .debriefSummary {
    margin-left: 750px !important;
  }
  .mtDate {
    margin-top: 50px !important;
  }
  .col-8-percent {
    margin-top: 10px !important;
  }
  .col-20-percent {
    margin-top: 10px !important;
  }
  .col-13-5-percent {
    margin-top: 10px !important;
  }
  .col-14-percent {
    margin-top: 10px !important;
  }
  .dateTop{
    margin-top: 48px !important;
  }
  .mapContainer {
    height: 600px;
    width: 1070px;
    border: 2px solid #000;
    border-radius: 5px;
  }
}
@media (max-width: 2400px) {
  .InputGroup input {
    width: 90%;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .debreifAlign{
    margin-left: 670px !important;
  }
  .debriefSummary {
    margin-left: 455px !important;
  }
  .mtDate {
    margin-top: 50px !important;
  }
  .col-8-percent {
    margin-top: 10px !important;
  }
  .col-20-percent {
    margin-top: 10px !important;
  }
  .col-13-5-percent {
    margin-top: 10px !important;
  }
  .col-14-percent {
    margin-top: 10px !important;
  }
  .mapContainer {
    height: 600px;
    width: 950px;
    border: 2px solid #000;
    border-radius: 5px;
  }
  .dateTop{
    margin-top: 48px !important;
  }
}
@media (max-width: 2350px) {
  .InputGroup input {
    width: 90%;
  }
  .debreifAlign{
    margin-left: 650px !important;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .debriefSummary {
    margin-left: 435px !important;
  }
  .mtDate {
    margin-top: 50px !important;
  }
  .col-8-percent {
    margin-top: 10px !important;
  }
  .col-20-percent {
    margin-top: 10px !important;
  }
  .col-13-5-percent {
    margin-top: 10px !important;
  }
  .col-14-percent {
    margin-top: 10px !important;
  }
  .dateTop{
    margin-top: 48px !important;
  }
}
@media (max-width: 2310px) {
  .InputGroup input {
    width: 90%;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .mtDate {
    margin-top: 50px !important;
  }
  .debriefSummary {
    margin-left: 510px !important;
  }
  .debreifAlign{
    margin-left: 690px !important;
  }
  .col-8-percent {
    margin-top: 10px !important;
  }
  .col-20-percent {
    margin-top: 10px !important;
  }
  .col-13-5-percent {
    margin-top: 10px !important;
  }
  .col-14-percent {
    margin-top: 10px !important;
  }
  .dateTop{
    margin-top: 48px !important;
  }
  .mapContainer {
    height: 600px;
    width: 950px;
    border: 2px solid #000;
    border-radius: 5px;
  }
}
@media (max-width: 2150px) {
  .InputGroup input {
    width: 90%;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .debreifAlign{
    margin-left: 600px !important;
  }
  .debriefSummary {
    margin-left: 380px !important;
  }
  .mtDate {
    margin-top: 50px !important;
  }
  .col-8-percent {
    margin-top: 10px !important;
  }
  .col-20-percent {
    margin-top: 10px !important;
  }
  .col-13-5-percent {
    margin-top: 10px !important;
  }
  .col-14-percent {
    margin-top: 10px !important;
  }
  .mapContainer {
    height: 600px;
    width: 820px;
    border: 2px solid #000;
    border-radius: 5px;
  }
}
@media (max-width: 1950px) {
  .InputGroup input {
    width: 90%;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .debreifAlign{
    margin-left: 550px !important;
  }
  .debriefSummary {
    margin-left: 305px !important;
  }
  .col-8-percent {
    margin-top: 10px !important;
  }
  .col-20-percent {
    margin-top: 10px !important;
  }
  .col-13-5-percent {
    margin-top: 10px !important;
  }
  .col-14-percent {
    margin-top: 10px !important;
  }
  .mapContainer {
    height: 600px;
    width: 755px;
    border: 2px solid #000;
    border-radius: 5px;
  }
}
@media (max-width: 1850px) {
  .carousel-container {
    height: 300px; /* Adjust to your desired height */
    overflow: hidden; /* Ensures that content outside this height doesn't show */
    margin-left: 100px !important;
  }
  .InputGroup input {
    width: 90%;
  }
  .debreifAlign{
    margin-left: 500px !important;
  }
  .debriefSummary {
    margin-left: 180px !important;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .col-8-percent {
    margin-top: 10px !important;
  }
  .col-20-percent {
    margin-top: 10px !important;
  }
  .col-13-5-percent {
    margin-top: 10px !important;
  }
  .col-14-percent {
    margin-top: 10px !important;
  }
}
@media (max-width: 1750px) {
  .InputGroup input {
    width: 90%;
  }
  .debriefSummary {
    margin-left: 210px !important;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .debreifAlign{
    margin-left: 450px !important;
  }
  .mt_60ops {
    margin-top: 105px;
  }
  .col-8-percent {
    margin-top: 10px !important;
  }
  .col-20-percent {
    margin-top: 10px !important;
  }
  .col-13-5-percent {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .col-14-percent {
    margin-top: 10px !important;
  }
  .mapContainer {
    height: 600px;
    width: 650px;
    border: 2px solid #000;
    border-radius: 5px;
  }
}
@media (max-width: 1600px) {
  .InputGroup input {
    width: 90%;
  }
  .mt_60ops {
    margin-top: 105px;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .debriefSummary {
    margin-left: 45px !important;
  }
  .debreifAlign{
    margin-left: 380px !important;
  }
  .col-8-percent {
    margin-top: 10px !important;
  }
  .col-20-percent {
    margin-top: 10px !important;
  }
  .col-13-5-percent {
    margin-top: 10px !important;
  }
  .col-14-percent {
    margin-top: 10px !important;
  }
  .mapContainer {
    height: 600px;
    width: 565px;
    border: 2px solid #000;
    border-radius: 5px;
  }
}
@media (max-width: 1580px) {
  .InputGroup input {
    width: 90%;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .mt_60ops {
    margin-top: 105px;
  }
  .debreifAlign{
    margin-left: 380px !important;
  }
  .col-13-percent {
    margin-right: 10px;
  }
  .debriefSummary {
    margin-left: 125px !important;
  }
  .col-8-percent {
    margin-right: 10px;
    width: 9% !important;
    margin-top: 10px !important;
  }
  .col-18-percent {
    width: 16% !important;
    margin-bottom: 10px;
  }
  .col-20-percent {
    width: 16% !important;
    margin-top: 10px !important;
  }
  .col-13-5-percent {
    width: 16% !important;
    margin-top: 10px !important;
  }
  .col-14-percent {
    width: 16% !important;
    margin-top: 10px !important;
  }
}
@media (max-width: 1450px) {
  .InputGroup input {
    width: 90%;
  }
  .InputGroupFilter input {
    width: 90%;
  }
  .mt_60ops {
    margin-top: 105px;
  }
  .debreifAlign{
    margin-left: 350px !important;
  }
  .debriefSummary {
    margin-left: 55px !important;
  }
  .col-13-percent {
    margin-right: 10px;
    width: 9% !important;
  }
  .col-8-percent {
    margin-right: 10px;
    width: 9% !important;
    margin-top: 10px !important;
  }
  .col-18-percent {
    width: 16% !important;
    margin-bottom: 10px;
  }
  .col-20-percent {
    width: 16% !important;
    margin-top: 10px !important;
  }
  .col-13-5-percent {
    width: 16% !important;
    margin-top: 10px !important;
  }
  .col-14-percent {
    width: 18.3% !important;
    margin-top: 10px !important;
  }
  .col-28-percent {
    width: 33% !important;
    margin-top: 10px !important;
  }
  .col-28-percent-ms {
    width: 33% !important;
  }
  .mapContainer {
    height: 600px;
    width: 500px;
    border: 2px solid #000;
    border-radius: 5px;
  }
}
@media only screen and (max-width: 1420px) and (min-width: 1030px) {
  label.fontSize14 {
    /* Adjust the styles for the label within the specified range */
    white-space: nowrap; /* Prevent wrapping to show the label without space */
  }
}
@media (max-width: 1390px) {
  .Maininput {
    width: 100%;
  }
  .InputGroup input {
    width: 160px;
  }
  .debreifAlign{
    margin-left: 330px !important;
  }
  .InputGroupFilter input {
    width: 80px;
  }
  .col-13-percent {
    margin-right: 10px;
    width: 12.4% !important;
  }
  .mt_60ops {
    margin-top: 105px;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .mt_Rates{
    margin-top: 10px !important;
  }
  .col-8-percent {
    margin-right: 10px;
    width: 12.4% !important;
    margin-top: 10px !important;
  }
  .col-18-percent {
    width: 16% !important;
    margin-bottom: 10px;
  }
  .col-20-percent {
    width: 16% !important;
    margin-top: 10px !important;
  }
  .jira-dropdown {
    margin-top: 10px;
  }
  .customFilter {
    margin-top: 10px;
  }
  .col-13-5-percent {
    width: 23% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 23% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .mleft_10 {
    margin-left: 10px;
    margin-top: 10px;
  }
  .mapContainer {
    height: 600px;
    width: 450px;
    border: 2px solid #000;
    border-radius: 5px;
  }
}
/* Styles for screens up to 1280x585 pixels */
@media (max-width: 1300px) {
  .Maininput {
    width: 100%;
  }
  .InputGroup input {
    width: 160px;
  }
  .InputGroupFilter input {
    width: 80px;
  }
  .col-13-percent {
    margin-right: 10px;
  }
  .mt_60ops {
    margin-top: 105px;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .debreifAlign{
    margin-left: 330px !important;
  }
  .col-8-percent {
    margin-right: 60px;
    margin-top: 10px !important;
  }
  .mright_50 {
    margin-right: 47px !important;
  }
  .col-18-percent {
    width: 22% !important;
    margin-right: 70px;
    margin-bottom: 10px;
  }
  .col-20-percent {
    width: 16% !important;
    margin-top: 10px !important;
  }
  .jira-dropdown {
    margin-top: 10px;
  }
  .customFilter {
    margin-top: 10px;
  }
  .col-13-5-percent {
    width: 23% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 23% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .mleft_10 {
    margin-left: 10px;
    margin-top: 10px;
  }
  .col-28-percent {
    width: 35% !important;
    margin-top: 10px !important;
  }
  .col-28-percent-ms {
    width: 35% !important;
  }
  .mapContainer {
    height: 600px;
    width: 440px;
    border: 2px solid #000;
    border-radius: 5px;
  }
}
@media (max-width: 1230px) {
  .Maininput {
    width: 100%;
  }
  .InputGroup input {
    width: 15px;
  }
  .InputGroupFilter input {
    width: 80px;
  }
  .mt_60ops {
    margin-top: 105px;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .debreifAlign{
    margin-left: 310px !important;
  }
  .col-13-percent {
    margin-right: 10px;
  }
  .col-8-percent {
    margin-right: 60px;
    margin-top: 10px !important;
  }
  .mright_50 {
    margin-right: 47px !important;
  }
  .col-18-percent {
    width: 22% !important;
    margin-right: 70px;
    margin-bottom: 10px;
  }
  .col-17-percent {
    width: 40% !important;
    margin-right: 70px;
    margin-bottom: 10px;
  }
  .col-20-percent {
    width: 16% !important;
    margin-top: 10px !important;
  }
  .jira-dropdown {
    margin-top: 10px;
  }
  .customFilter {
    margin-top: 10px;
  }
  .col-13-5-percent {
    width: 23% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 23% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .mleft_10 {
    margin-left: 10px;
    margin-top: 10px;
  }
  .col-28-percent {
    width: 35% !important;
    margin-top: 10px !important;
  }
  .col-28-percent-ms {
    width: 35% !important;
  }
  .mapContainer {
    height: 600px;
    width: 420px;
    border: 2px solid #000;
    border-radius: 5px;
  }
}
@media (max-width: 1165px) {
  .customDateRange .rs-picker-toggle-wrapper {
    width: 80%;
    .col-13-percent {
      margin-right: 10px;
    }
    .col-8-percent {
      margin-right: 60px;
      margin-top: 10px;
    }
    .col-18-percent {
      width: 22% !important;
      margin-right: 70px;
      margin-bottom: 10px;
    }
    .debreifAlign{
      margin-left: 280px !important;
    }
    .debriefSummary {
      margin-left: 0px !important;
    }
    .mt_60ops {
      margin-top: 105px;
    }
    .col-17-percent {
      width: 22% !important;
      margin-right: 70px;
      margin-bottom: 10px;
    }
    .col-20-percent {
      width: 22% !important;
      margin-right: 70px;
      margin-bottom: 10px;
    }
    .col-13-5-percent {
      width: 22% !important;
      margin-right: 70px;
      margin-bottom: 10px;
    }
    .col-14-percent {
      width: 24% !important;
      margin-right: 80px;
      margin-bottom: 10px;
    }
    .col-28-percent {
      width: 43% !important;
      margin-top: 10px !important;
    }
    .col-28-percent-ms {
      width: 43% !important;
    }
    .col-40-percent {
      width: 50% !important;
      margin-top: 10px !important;
    }
    .col-40-percent-ms {
      width: 50% !important;
    }
    .col-25-percent {
      width: 30% !important;
      margin-right: 80px;
      margin-bottom: 10px;
    }
  }
}
@media (max-width: 1130px) {
  .InputGroup input {
    width: 140px;
  }
  .customDateRange .rs-picker-toggle-wrapper {
    width: 78%;
  }
  .InputGroupFilter input {
    width: 60px;
  }
  .col-13-percent {
    margin-right: 10px;
  }
  .col-8-percent {
    margin-right: 10px;
    margin-top: 10px !important;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .col-18-percent {
    width: 22% !important;
    margin-right: 70px;
    margin-bottom: 10px;
  }
  .col-20-percent {
    width: 23% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-13-5-percent {
    width: 30% !important;
    margin-right: 70px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 30% !important;
    margin-right: 70px;
    margin-bottom: 10px;
  }
  .col-25-percent {
    width: 28% !important;
    margin-right: 70px;
    margin-bottom: 10px;
  }
  .mapContainer {
    height: 600px;
    width: 350px;
    border: 2px solid #000;
    border-radius: 5px;
  }
  .smallGroupInput {
    display: block;
  }
}
@media (max-width: 1050px) {
  .InputGroup input {
    width: 140px;
  }
  .customDateRange .rs-picker-toggle-wrapper {
    width: 78%;
  }
  .InputGroupFilter input {
    width: 60px;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .col-13-percent {
    margin-right: 10px;
  }
  .col-8-percent {
    margin-right: 10px;
  }
  .col-20-percent {
    width: 30% !important;
    margin-right: 70px;
    margin-bottom: 10px;
  }
  .col-13-5-percent {
    width: 30% !important;
    margin-right: 70px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 30% !important;
    margin-right: 70px;
    margin-bottom: 10px;
  }
  .col-18-percent {
    width: 22% !important;
    margin-right: 70px;
    margin-bottom: 10px;
  }
  .col-25-percent {
    width: 28% !important;
    margin-right: 70px;
    margin-bottom: 10px;
  }
  .col-35-percent {
    width: 50% !important;
    margin-bottom: 10px;
  }
  .col-28-percent {
    width: 50% !important;
    margin-top: 10px !important;
  }
  .col-28-percent-ms {
    width: 50% !important;
  }
  .mapContainer {
    height: 600px;
    width: 315px;
    border: 2px solid #000;
    border-radius: 5px;
  }
}
@media (max-width: 1030px) {
  .InputGroup input {
    width: 75px;
  }
  .InputGroupFilter input {
    width: 30px;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .col-13-percent {
    margin-right: 10px;
    width: 22.8% !important;
  }
  .col-8-percent {
    margin-right: 10px;
    width: 34.8% !important;
  }
  .col-18-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .customDateRange .rs-picker-toggle-wrapper {
    width: 85%;
  }
  .col-20-percent {
    width: 35% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-13-5-percent {
    width: 35% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 35% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-25-percent {
    width: 60% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-35-percent {
    width: 50% !important;
    margin-bottom: 10px;
    margin-left: -3px;
  }
  .col-28-percent {
    width: 51% !important;
    margin-top: 10px !important;
  }
  .col-28-percent-ms {
    width: 51% !important;
  }
  .col-40-percent {
    width: 75% !important;
    margin-top: 10px !important;
  }
  .col-40-percent-ms {
    width: 75% !important;
  }
  .col-50-percent-ms {
    width: 85% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
}
@media (max-width: 1000px) {
  .InputGroup input {
    width: 75px;
  }
  .InputGroupFilter input {
    width: 30px;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .col-13-percent {
    margin-right: 10px;
    width: 22.8% !important;
  }
  .col-8-percent {
    margin-right: 10px;
    width: 34.8% !important;
  }
  .col-18-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-17-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .customDateRange .rs-picker-toggle-wrapper {
    width: 85%;
  }
  .col-20-percent {
    width: 35% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-13-5-percent {
    width: 35% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 35% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-25-percent {
    width: 60% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-35-percent {
    width: 50% !important;
    margin-bottom: 10px;
  }
  .mapContainer {
    height: 600px;
    width: 260px;
    border: 2px solid #000;
    border-radius: 5px;
  }
}
@media (max-width: 950px) {
  .InputGroup input {
    width: 75px;
  }
  .col-13-percent {
    margin-right: 10px;
    width: 22.8% !important;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .col-8-percent {
    margin-right: 10px;
    width: 39.8% !important;
  }
  .col-18-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-17-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .InputGroupFilter input {
    width: 30px;
  }
  .customDateRange .rs-picker-toggle-wrapper {
    width: 85%;
  }
  .col-20-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-13-5-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-25-percent {
    width: 60% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
}
@media (max-width: 900px) {
  .InputGroup input {
    width: 75px;
  }
  .col-8-percent {
    margin-right: 10px;
    width: 34.8% !important;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .col-8-percent {
    margin-right: 10px;
    width: 39.8% !important;
  }
  .col-18-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-17-percent {
    width: 70% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .InputGroupFilter input {
    width: 30px;
  }
  .customDateRange .rs-picker-toggle-wrapper {
    width: 85%;
  }
  .col-20-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-13-5-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-25-percent {
    width: 60% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
}
@media (max-width: 870px) {
  .InputGroup input {
    width: 85px;
  }
  .InputGroupFilter input {
    width: 35px;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .customDateRange .rs-picker-toggle-wrapper {
    width: 90%;
  }
  .marginRight500 {
    margin-right: 520px !important;
  }
  .col-13-percent {
    margin-right: 10px;
    width: 22.8% !important;
  }
  .col-8-percent {
    margin-right: 50px;
    width: 44.8% !important;
    margin-bottom: 10px;
  }
  .col-18-percent {
    width: 50% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-17-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-20-percent {
    width: 45% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-13-5-percent {
    width: 45% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 45% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-50-percent {
    width: 85% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-50-percent-ms {
    width: 85% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-35-percent {
    width: 85% !important;
    margin-bottom: 10px;
  }
  .col-25-percent {
    width: 50% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-28-percent {
    width: 67% !important;
    margin-top: 10px !important;
  }
  .col-28-percent-ms {
    width: 67% !important;
  }
  .col-40-percent {
    width: 95% !important;
    margin-top: 10px !important;
  }
  .col-40-percent-ms {
    width: 95% !important;
  }
  .col-50-percent {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .col-50-percent-ms {
    width: 100% !important;
  }
}
@media (max-width: 850px) {
  .InputGroup input {
    width: 40px;
  }
  .customDateRange .rs-picker-toggle-wrapper {
    width: 90%;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .col-13-percent {
    margin-right: 10px;
    width: 22.8% !important;
  }
  .col-8-percent {
    margin-right: 10px;
    width: 45% !important;
  }
  .col-18-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-17-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-20-percent {
    width: 45% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-13-5-percent {
    width: 45% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 45% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-50-percent {
    width: 85% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-50-percent-ms {
    width: 85% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-35-percent {
    width: 85% !important;
    margin-bottom: 10px;
  }
  .col-28-percent {
    width: 67% !important;
    margin-top: 10px !important;
  }
  .col-28-percent-ms {
    width: 67% !important;
  }
  .col-40-percent {
    width: 95% !important;
    margin-top: 10px !important;
  }
  .col-40-percent-ms {
    width: 95% !important;
  }
  .col-50-percent {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .col-50-percent-ms {
    width: 100% !important;
  }
}
@media (max-width: 810px) {
  .InputGroup input {
    width: 30px;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .col-13-percent {
    margin-right: 10px;
    width: 22.8% !important;
  }
  .col-8-percent {
    margin-right: 10px;
    width: 50% !important;
  }
  .col-18-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-17-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .customDateRange .rs-picker-toggle-wrapper {
    width: 85%;
  }
  .col-20-percent {
    width: 50% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-13-5-percent {
    width: 50% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 50% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-50-percent {
    width: 85% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-50-percent-ms {
    width: 85% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-35-percent {
    width: 85% !important;
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .InputGroup input {
    width: 150px;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .InputGroupFilter input {
    width: 50px;
  }
  .customDateRange .rs-picker-toggle-wrapper {
    width: 85%;
  }
  .col-13-percent {
    margin-right: 10px;
    width: 22.8% !important;
  }
  .col-8-percent {
    margin-right: 10px;
    width: 55% !important;
  }
  .col-18-percent {
    width: 40% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-17-percent {
    width: 60% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-20-percent {
    width: 100% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-13-5-percent {
    width: 100% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 100% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-50-percent {
    width: 85% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-50-percent-ms {
    width: 85% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-35-percent {
    width: 85% !important;
    margin-bottom: 10px;
  }
  .col-28-percent {
    width: 67% !important;
    margin-top: 10px !important;
  }
  .col-28-percent-ms {
    width: 67% !important;
  }
  .col-40-percent {
    width: 95% !important;
    margin-top: 10px !important;
  }
  .col-40-percent-ms {
    width: 95% !important;
  }
  .col-50-percent {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .col-50-percent-ms {
    width: 100% !important;
  }
}
@media (max-width: 740px) {
  .InputGroup input {
    width: 200px;
  }
  .InputGroupFilter input {
    width: 80px;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .col-13-percent {
    margin-right: 10px;
    width: 75% !important;
  }
  .col-8-percent {
    margin-right: 10px;
    width: 80% !important;
  }
  .col-18-percent {
    width: 75% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-17-percent {
    width: 75% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-20-percent {
    width: 80% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-13-5-percent {
    width: 80% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 80% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-50-percent {
    width: 100% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-50-percent-ms {
    width: 100% !important;
    margin-right: 50px;
    margin-bottom: 10px;
  }
  .col-28-percent {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .col-28-percent-ms {
    width: 100% !important;
    margin-top: 10px !important;
  }
  .col-40-percent {
    width: 95% !important;
    margin-top: 10px !important;
  }
  .col-40-percent-ms {
    width: 95% !important;
  }
  .col-35-percent {
    width: 100% !important;
    margin-bottom: 10px;
  }
  .col-25-percent {
    width: 100% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .customDateRange .rs-picker-toggle-wrapper {
    width: 85%;
  }
  .mb-fil {
    margin-bottom: 10px !important;
  }
  .mainInput {
    width: 55%;
    margin-bottom: 10px;
  }
  .justifyContent_end {
    justify-content: normal;
  }
}
@media (max-width: 690px) {
  .InputGroup input {
    width: 200px;
  }
  .InputGroupFilter input {
    width: 80px;
  }
  .col-13-percent {
    margin-right: 80% !important;
    margin-bottom: 10px;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .col-8-percent {
    width: 90% !important;
    margin-right: 90% !important;
    margin-bottom: 10px;
  }
  .col-20-percent {
    width: 90% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-13-5-percent {
    width: 90% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 90% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-18-percent {
    width: 75% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-17-percent {
    width: 75% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-25-percent {
    width: 100% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .customDateRange .rs-picker-toggle-wrapper {
    width: 70%;
  }
  .mb-fil {
    margin-bottom: 10px !important;
  }
  .mainInput {
    width: 55%;
    margin-bottom: 10px;
  }
  .justifyContent_end {
    justify-content: normal;
  }
}
@media (max-width: 650px) {
  .InputGroup input {
    width: 200px;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .InputGroupFilter input {
    width: 80px;
  }
  .col-13-percent {
    margin-right: 90% !important;
    margin-bottom: 10px;
  }
  .col-8-percent {
    margin-right: 90% !important;
    margin-bottom: 10px;
  }
  .col-20-percent {
    width: 90% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-13-5-percent {
    width: 90% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 90% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-18-percent {
    width: 75% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-17-percent {
    width: 75% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-25-percent {
    width: 90% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .customDateRange .rs-picker-toggle-wrapper {
    width: 70%;
  }
  .mb-fil {
    margin-bottom: 10px !important;
  }
  .justifyContent_end {
    justify-content: normal;
  }
}
@media (max-width: 550px) {
  .InputGroup input {
    width: 200px;
  }
  .InputGroupFilter input {
    width: 80px;
  }
  .debriefSummary {
    margin-left: 0px !important;
  }
  .col-13-percent {
    margin-right: 60px;
    margin-bottom: 10px;
  }
  .col-20-percent {
    width: 90% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-13-5-percent {
    width: 90% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-14-percent {
    width: 90% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-18-percent {
    width: 80% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-17-percent {
    width: 80% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .col-25-percent {
    width: 80% !important;
    margin-right: 150px;
    margin-bottom: 10px;
  }
  .customDateRange .rs-picker-toggle-wrapper {
    width: 100%;
  }
  .mb-fil {
    margin-bottom: 10px !important;
  }
  .justifyContent_end {
    justify-content: normal;
  }
}
//breadcrumbs
ul.page_breadcrumb {
  display: flex;
  align-items: center;
  // margin-bottom: 61px;
  // margin-bottom: 24px;
  padding: 0;
  gap: 10px;
}
ul.page_breadcrumb li,
ul.page_breadcrumb li a {
  text-decoration: none;
  // line-height: 1.5;
  margin-bottom: 0px;
  display: block;
  font-size: 14px;
  margin-top: 0px !important;
  color: var(--white);
  // font-weight: bolder;
  // line-height: 1.5;
  cursor: pointer;
}

ul.page_breadcrumb li a:hover {
  color: red;
}

.popupheadinglogo img {
  filter: brightness(0) invert(1);
  height: 33px;
}

.deletebtn {
  padding: 0px 14px;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 500;
  outline: unset;
  height: 38px;
  border-radius: 5px;
  width: 100%;
}
.cancelPrimary {
  border: 1px solid #ffffff;
  background-color: #ffffff;
  color: #000;
}
.confirmPrimary {
  border: 1px solid #ec171c;
  background-color: #ec171c;
  color: #ffffff;
}

.rs-picker-toggle-value {
  color: #1c1d1f !important;
  font-size: 12px;
}

.rs-stack {
  font-size: 13px;
}

.rs-picker-menu {
  z-index: 7777 !important;
}
.table tr.selectRow {
  background-color: var(--sefidWhiteColor);
}
.table tr.selectRow td {
  background-color: var(--sefidWhiteColor);
}
.deliveredDateRange .rs-picker-toggle-wrapper {
  width: 100%;
}
.deliveredDateRange .rs-picker-default .rs-picker-toggle {
  height: 36px;
  // border-radius: 10px;
}
.deliveredDateRange .rs-picker-toggle {
  border: 1px solid #bcc3c7 !important;
}
.deliveredDateRange .rs-picker:not(.rs-picker-disabled):hover,
.customDateRange .rs-picker:not(.rs-picker-disabled):focus {
  // box-shadow: unset;
  border-color: unset;
}
.deliveredDateRangeevent .rs-picker-toggle-wrapper {
  width: 100%;
}
.deliveredDateRangeevent .rs-picker-default .rs-picker-toggle {
  height: 36px;
  // border-radius: 10px;
}
.deliveredDateRangeevent .rs-picker-toggle {
  border: 1px solid #bcc3c7 !important;
}
.deliveredDateRangeevent .rs-picker:not(.rs-picker-disabled):hover,
.customDateRange .rs-picker:not(.rs-picker-disabled):focus {
  // box-shadow: unset;
  border-color: unset;
}

.page_404 {
  min-height: 50vh; /* Ensure it spans the viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; /* Optional for text alignment */
}


.link_404 {
  color: #fafbfc !important;
  padding: 10px 20px;
  background: #d30b24;
  margin: 20px 0;
  display: inline-block;
}

.groupHeadButton .btn-primary {
  background-color: unset;
  border: unset;
  border-radius: 0px;
  padding: 0px;
  color: #707070;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
}
.groupHeadButton .btn:first-child:active {
  background-color: unset;
  color: transparent;
  border: 1px solid transparent;
}
.groupHeadButton .btn-primary:hover {
  background-color: unset;
  color: #707070;
  border: 1px solid transparent;
}
.groupHeadButton .btn-primary:active {
  color: #707070;
}
.groupHeadButton .btn-primary:focus {
  background-color: transparent;
  border: 1px solid transparent;
  color: transparent;
}

.groupHeadButton img {
  width: 30px;
  height: 30px;
}

.dropdown-toggle::after {
  vertical-align: 0.155em !important;
}

.groupHeadButton .dropdown-menu.show {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: 1px solid transparent;
  border-radius: 5px !important;
  top: 0px !important;
}

.groupHeadButton .dropdown-menu.show a {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
}

.groupHeadButton .btn {
  transition: none !important;
}

.groupHeadButton .dropdown-menu.show a:hover {
  text-decoration: none !important;
}

.groupHeadButton .dropdown-toggle::after {
  display: none;
}

.Secondary_color {
  background-color: #959595;
  border: 1px solid #959595;
  color: #ffffff;
}
.Main_btn {
  // width: 100%;
  height: 36px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 600;
  padding: 0px 16px;
  border-radius: 7px;
  outline: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 210px;
}
.btn.show {
  background-color: transparent !important;
  border-color: transparent !important;
}

.treeGroup > div {
  width: 100% !important;
}
.treeGroup > div > div {
  width: 100% !important;
}
.mt_-55{
  margin-top: -55px !important;
}
.Groupinput {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
}
.Groupinput input {
  background: #fff;
  border: 1px solid #909497;
  border-right: unset !important;
  border-radius: 3px 0 0 3px;
  height: 36px;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: -0.006em;
  color: #252c32;
  padding: 0px 6px;
  outline: unset;
}
.Groupinput .Groupinput_text {
  height: 36px;
  background-color: #fff;
  border-left: unset !important;
  border: 1px solid #909497;
  border-radius: 0 3px 3px 0;
  display: flex;
  align-items: center;
  padding: 0 8px;
  margin-bottom: 0;
  text-align: center;
  white-space: nowrap;
}

.Groupinput:focus-within:not([data-disabled]) input {
  background: var(--ds-background-input-pressed, #ffffff);
  border-color: var(--ds-border-focused, #EC171C);
  border: 2px solid #EC171C;
}

.Groupinput:focus-within:not([data-disabled]) .Groupinput_text {
  background: var(--ds-background-input-pressed, #ffffff);
  border-color: var(--ds-border-focused, #EC171C);
  border: 2px solid #EC171C;
}

.Grpinput {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 100%;
}

/* Styles for input field */
.Grpinput input {
  border: 1px solid #909497;
}

/* Styles for input container when focused */
.Grpinput:focus-within:not([data-disabled]) input,
.Grpinput:focus-within:not([data-disabled]) .Groupinput_text {
  background: var(--ds-background-input-pressed, #ffffff);
  border: 2px solid #EC171C;
  border-color: var(--ds-border-focused, #EC171C);
}
.GrpinputOrders {
  position: relative;
  display: flex;
  align-items: stretch;
  width: 102%;
}

/* Styles for input field */
.GrpinputOrders input {
  border: 1px solid #909497;
}

/* Styles for input container when focused */
.GrpinputOrders:focus-within:not([data-disabled]) input,
.GrpinputOrders:focus-within:not([data-disabled]) .Groupinput_text {
  background: var(--ds-background-input-pressed, #ffffff);
  border: 2px solid #EC171C;
  border-color: var(--ds-border-focused, #EC171C);
}
